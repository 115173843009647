import { Component, OnInit } from '@angular/core';
import { VolinfoService } from '../../services/volinfo.service';
import { PopoverController } from '@ionic/angular';

import { PopoverPage } from '../library-popover/library-popover';
import { DbService } from '../../services/db.service';
import { ModalController, NavParams } from '@ionic/angular';
import { DbLocalSettingsService } from '../../services/db-local-settings.service';

import { Volume } from '../../interfaces/volume';
import { Platform } from '@ionic/angular';
import { LibraryDetailPage } from '../library-detail/library-detail.page';

import { firstBy } from 'thenby';
import { DownloadService } from '../../services/download.service';
import { DownloadState } from '../../interfaces/downloadstate';

import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../interfaces/user';
import { LibraryService } from '../../services/library.service';

// import { LongPressService } from '../../services/long-press.service';
@Component({
  selector: 'app-library',
  templateUrl: './library.page.html',
  styleUrls: ['./library.page.scss'],
})
export class LibraryPage implements OnInit {
  public librarySettings = { wrapMode: true, volumeClass: 'BIBLE' };
  public getCategoryTitle = this.libraryService.getCategoryTitle;
  public downloadState: DownloadState = {
    queue: [],
    downloading: false,
    currentFile: '',
    loaded: 0,
    total: 0
  };

  public volumeClasses = ['BIBLE', 'COMM', 'MEDIA', 'DICT', 'BOOK'];
  // public volumes: Volume[];
  public library = {
    'BIBLE': {
      'favorites': [],
      'installed': [],
      'purchased': [],
      'available': []
    },
    'COMM': {
      'favorites': [],
      'installed': [],
      'purchased': [],
      'available': []
    },
    'MEDIA': {
      'favorites': [],
      'installed': [],
      'purchased': [],
      'available': []
    },
    'DICT': {
      'favorites': [],
      'installed': [],
      'purchased': [],
      'available': []
    },
    'BOOK': {
      'favorites': [],
      'installed': [],
      'purchased': [],
      'available': []
    },
  };
  // public volumeClass = ''; // = 'BIBLE';
  public searchMode = false;
  public searchToken = '';
  public searchResults: Volume[] = [];
  public isCordova = false;
  public counters = {
    favorite: 0,
    installed: 0,
    ready: 0,
    available: 0,
    searchResults: 0
  };
  private subscription: any = {};
  public recentVolumes = [];
  animating = false;

  constructor(
    private libraryService: LibraryService,
    public popoverCtrl: PopoverController,
    private volinfoService: VolinfoService,
    private db: DbService,
    private platform: Platform,
    private modalController: ModalController,
    private navParams: NavParams,
    public downloadService: DownloadService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dbLocalSettingsService: DbLocalSettingsService,
    // private longPressService: LongPressService
  ) {
    this.isCordova = this.platform.is('cordova');
  }

  ngOnInit() {
    this.dbLocalSettingsService.getLocalSetting('librarySettings')
    .then((librarySettings: any) => {
      if (librarySettings) {
        // this.volumeClass = librarySettings.volumeClass;
        this.librarySettings = librarySettings;
        // console.log('loaded librarySettings', this.librarySettings);
      }
    }).catch((err) => {
      console.error('error getting volumeClass from local settings', err);
    });
    this.loadLibrary();

    /*
    this.longPressService.press.subscribe((press) => {
      if (press) {
          const targetID = this.recursiveFinder($event.target);
          console.log('** Library long press targetID', targetID);
          if (targetID && targetID.startsWith('longPress-')) {
            this.libraryService.toggleFavorite(targetID.replace('longPress-', ''));
          }
        }
      }
    });
    */

  }

  /*
  recursiveFinder(element) {
    let i = 0;
    while (element && i < 15) {
      i++;
      if (element && element.id && element.id.startsWith('longPress-')) {
        return (element.id);
      }
      if (!element || !element.parentElement) {
        return null;
      }
      if (element.parentElement) {
        element = element.parentElement;
      }
    }
    return null;
  }
  */

  ionViewWillEnter() {
    window.name = 'Library';
    // console.log('*** library.page.js ionViewWillEnter()');
    this.libraryService.getBookshelf();
    this.dbLocalSettingsService.getLocalSetting('recentVolumes')
    .then((recentVolumes: any) => {
      if (recentVolumes) {
        if (recentVolumes[0] && recentVolumes[0].tbl) {
          // convert recentVolumes to array of strings
          const newRecentVolumes = [];
          for (let rv = 0; rv < recentVolumes.length; rv++) {
            newRecentVolumes.push(recentVolumes[rv].tbl);
          }
          this.recentVolumes = newRecentVolumes;
          recentVolumes = newRecentVolumes;
        }
        this.recentVolumes = recentVolumes;
      }
    });
    if (this.isCordova) {
      this.subscription.state = this.downloadService.downloadState.subscribe((state) => {
        // console.log('** downloadState', this.downloadState);
        this.downloadState = state;
        this.changeDetectorRef.detectChanges();
        // console.log('*** downloadState', state);
      });
      this.subscription.bookshelf = this.downloadService.bookshelfChanged.subscribe((updated) => {
        if (updated) {
          // console.log('** downloadService.bookshelfChanged returned true -- updating this.getBookshelf()');
          this.libraryService.getBookshelf(true);
        }
      });
      this.subscription.downloadsComplete = this.downloadService.downloadsComplete.subscribe((complete) => {
        // console.log('** downloadService.downloadsComplete fired');
        this.libraryService.getBookshelf(true);
      });
    }
  }
  ionViewWillLeave() {
    if (this.isCordova) {
      this.subscription.state.unsubscribe();
      this.subscription.bookshelf.unsubscribe();
      this.subscription.downloadsComplete.unsubscribe();
    }
  }

  loadLibrary() {
    this.libraryService.loadFavorites()
    .then(() => {
      this.volumeClasses.map((volumeClass) => {
        this.library[volumeClass].favorites =
          this.libraryService.getFavoriteVolumes(volumeClass);
        if (this.isCordova) {
          this.library[volumeClass].installed =
          this.libraryService.volumes.filter((item) => {
            return item.installed === 'Y';
          });
        }
        this.library[volumeClass].purchased =
          this.libraryService.volumes.filter((item) => {
            if (this.isCordova) {
              return (item.volumeClass === volumeClass && item.purchased === 'Y' && item.installed !== 'Y');
            } else {
              return (
                item.volumeClass === volumeClass &&
                item.purchased === 'Y' &&
                this.libraryService.favorites[volumeClass].indexOf(item.tbl) === -1
              );
            }
          });
        this.library[volumeClass].available =
          this.libraryService.volumes.filter((item) => {
            return (item.volumeClass === volumeClass && item.purchased !== 'Y');
          });
      });
      // console.log('*** library', this.library);
    }).catch((err) => {
      console.error('loadLibrary could not load favorites', err);
    });

  }

  doReorder($event: any) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', $event.detail.from, 'to', $event.detail.to);
    $event.stopPropagation();
    const from = $event.detail.from;
    const to = $event.detail.to;
    this.move(from, to, this.library[this.librarySettings.volumeClass].favorites);
    $event.detail.complete(this.library[this.librarySettings.volumeClass].favorites);
    this.libraryService.favorites[this.librarySettings.volumeClass] = this.library[this.librarySettings.volumeClass].favorites.map((vol) => vol.tbl);
    this.libraryService.saveFavorites();
  }
  move(from, to, ...a) {
    return (from === to
    ? a
    : (a.splice(to, 0, ...a.splice(from, 1)), a));
  }

  addFavorite($event, vol, index, fromArray) {
    $event.stopPropagation();
    if (this.animating) {
      return;
    }
    this.animating = true;
    // console.log('** addFavorite', vol);
    const heartImage: any = document.getElementById('heart-' + vol.tbl);
    heartImage.name = 'heart'; // make it a solid heart

    const _this = this;
    let delay = 60;
    function animateUp() {
      delay = delay - 10;
      if (delay < 0) { delay = 0; }
      if (index < 0) {
        _this.libraryService.addToFavorites(vol, false);
        // _this.loadData();
        _this.library[_this.librarySettings.volumeClass].favorites.push(vol);
        _this.changeDetectorRef.detectChanges();
        _this.animating = false;
        return;
      } else {
        const newIndex = index - 1;
        const removedItems = fromArray.splice(index, 1);
        index--;
        if (index >= 0) {
          fromArray.splice(newIndex, 0, removedItems[0]);
        }
        _this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          animateUp();
        }, delay);
      }
    }
    animateUp();

  }

  removeFavorite($event, vol, index, fromArray) {
    $event.stopPropagation();
    if (this.animating) {
      return;
    }
    this.animating = true;
    const heartImage: any = document.getElementById('heart-' + vol.tbl);
    heartImage.name = 'heart-outline'; // make it a solid heart

    // this.libraryService.removeFromFavorites(vol);
    // this.loadData();
    const _this = this;
    let delay = 60;
    function animateDown() {
      delay = delay - 10;
      if (delay < 0) { delay = 0; }
    if (index > fromArray.length - 1) {
        _this.libraryService.removeFromFavorites(vol);
        // _this.loadData();
        _this.library[_this.librarySettings.volumeClass].purchased.unshift(vol); // fix this for installed maybe?
        _this.changeDetectorRef.detectChanges();
        _this.animating = false;
        return;
      } else {
        const newIndex = index + 1;
        const removedItems = fromArray.splice(index, 1);
        index++;
        if (index < fromArray.length) {
          fromArray.splice(newIndex, 0, removedItems[0]);
        }
        _this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          animateDown();
        }, delay);
      }
    }
    animateDown();
  }

  async presentPopover(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: PopoverPage,
      event,
      componentProps: { librarySettings: this.librarySettings }
    });
    popover.onDidDismiss().then((result: any) => {
      // console.log('** popover result', result);
      // console.log('librarySettings is now', this.librarySettings);
      this.saveLibrarySettings();
      if (result && result.data && result.data.message) {
        switch (result.data.message) {
          case 'sortByName':
            this.sortVolumes('name');
            break;
          case 'sortByTbl':
            this.sortVolumes('tbl');
            break;
          case 'clearRecentVolumes':
            this.clearRecentVolumes();
            break;
          default:
            console.error('unknown result from library popover', result);
            break;
        }
      }
    }).catch((err) => {
      console.error('library popover onDidDismiss error', err);
    });
    await popover.present();
  }

  async closeModal(cancel?: boolean, vol?: Volume) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { vol: vol }
    );
  }

  async libraryDetail(volume: Volume): Promise<any> {
    const modal = await this.modalController.create({
      component: LibraryDetailPage,
      componentProps: {
        volume: volume
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      if (dataReturned && dataReturned.data) {
        // console.log('LibraryDetailPage dataReturned', dataReturned);
        const data = dataReturned.data;
        const volumeToDelete = data.volume;
       //  console.log('LibraryDetailPage data', data);
        if (data.deleteThisVolume) {
          // console.log('*** delete this volume here:', volumeToDelete.tbl);
          this.downloadService.deleteVolume(volumeToDelete.tbl)
          .then((result) => {
            // console.log(volumeToDelete + ' deleted', result);
            this.libraryService.getBookshelf(true);
          }).catch((err) => {
            console.error('Could not delete ' + volumeToDelete, err);
          });
        }
      }
    });
    return await modal.present();
  }

  public isFavorite(item) {
    return this.libraryService.isFavorite(item);
  }

  segmentChanged($event) {
    // console.log('segmentChanged $event', $event);
    this.changeDetectorRef.detectChanges();
    this.saveLibrarySettings();
  }
  saveLibrarySettings() {
    this.dbLocalSettingsService.setLocalSetting('librarySettings', this.librarySettings)
    .then((success) => {
      // console.log('saved librarySettings', success, this.librarySettings);
    }).catch((failure) => {
      console.error('error saving librarySettings to local storage', failure);
    });
  }
  search($event) {
    this.searchToken = '';
    this.searchMode = !this.searchMode;
    this.searchFunction();
  }
  cancelSearch() {
    this.searchToken = '';
    this.searchMode = false;
  }
  clearSearch() {
    this.counters.searchResults = 0;
  }
  public filterFunction(criteria: any[], counterName): Volume[] {
    if (!this.libraryService.volumes) {
      return [];
    } else {
      const items = this.libraryService.volumes.filter((item) => {
        let retval = true;
        for (let i = 0; i < criteria.length; i++) {
          const filt = criteria[i];
          // console.log('*** filt', filt);
          if (filt[0] === 'favorite') {
            // filter by looking to see if this item is a favorite
            if (!this.libraryService.favorites || !this.libraryService.favorites[this.librarySettings.volumeClass]) {
              retval = false;
              break;
            }
            if (this.libraryService.favorites[this.librarySettings.volumeClass].indexOf(item.tbl) === -1 || (item.volumeClass !== this.librarySettings.volumeClass)) {
              retval = false;
              break;
            }
          } else {
            if (!(item[filt[0]] === filt[1]) || (item.volumeClass !== this.librarySettings.volumeClass)) {
              retval = false;
              break;
            }
          }
        }
        return (retval);
      });
      this.counters[counterName] = items.length;
      return items;
    }
  }

  public searchFunction(): void {
    // console.log('*** searchFunction');
    // console.log('this.searchToken', this.searchToken);
    if (!this.libraryService.volumes || this.searchToken.trim().length === 0) {
      // console.log('early return of empty results');
      // if (!this.libraryService.volumes) {
        // console.log('!this.libraryService.volumes');
      // }
      // if (this.searchToken.trim().length === 0) {
        // console.log('this.searchToken.trim().length === 0');
      // }
      this.searchResults = [];
    } else {
      const token = this.searchToken.toLowerCase().trim();
      // console.log('token', token);
      let items;

      items = this.libraryService.volumes.filter((item) => {
        if (!item.abstract) {
          item.abstract = '';
        }
        if (item.name.toLowerCase().indexOf(token) > -1 ||
        item.tbl.toLowerCase().indexOf(token) > -1 ||
        item.abbr.toLowerCase().indexOf(token) > -1 ||
        item.comments.toLowerCase().indexOf(token) > -1 ||
        item.abstract.toLowerCase().indexOf(token) > -1) {
          return true;
        } else {
          return false;
        }
      });
      // console.log('items.length', items.length);
      // console.log('items', items);
      this.counters.searchResults = items.length;

      const volumeClassCustomSort = (vc) => {
        switch (vc.toLowerCase()) {
          case 'bible':
            return 'F';
          case 'comm':
            return 'E';
          case 'media':
            return 'D';
          case 'dict':
            return 'C';
          case 'book':
            return 'B';
          default:
            return 'A';
        }
      };
  
      items.sort(
        firstBy(function (v1, v2) { return volumeClassCustomSort(v1.volumeClass) > volumeClassCustomSort(v2.volumeClass); }, -1)
        .thenBy('title', -1)
      );
      const results = [];
      // group them by volumeClass
      let volumeClass = '';
      for (let i = 0; i < items.length; i++) {
        const vol: Volume = items[i];

        if (vol.volumeClass !== volumeClass) {
          results.push(this.makeSearchResultHeader(vol.volumeClass));
          volumeClass = vol.volumeClass;
        }
        results.push(vol);
      }
      this.searchResults = results;
    }
  }

  makeSearchResultHeader(volumeClass) {
    return {
      name: this.libraryService.getCategoryTitle(volumeClass),
      tbl: 'HEADER',
      abbr: '',
      comments: '',
      copyright: '',
      class: '',
      installed: '',
      date: '',
      available: '',
      installedVersion: '',
      latestVersion: '',
      installKey: '',
      category: '',
      abstract: '',
      purchased: '',
      REV: '',
      volumeClass: volumeClass
    };
  }

  public searchClick(volume) {
    // console.log('searchClick', volume);
    switch (volume.purchased.toUpperCase() + volume.installed.toUpperCase()) {
      case 'YY': // purchased, installed
        this.closeModal(false, volume.tbl.toLowerCase()); // load it
        break;
      case 'YN': // purchased, not installed
        this.download(volume);
        this.clearSearch();
        this.cancelSearch();
        break;
      case 'NY': // not purchased, installed
      case 'NN': // not purchased, not installed
        this.libraryDetail(volume);
        break;
      default:
        console.error('searchClick -- unable to handle volume', volume);
        break;
    }
  }

  loadVolume(volume: any) {
    if (volume.purchased && volume.purchased !== 'Y') {
      this.getInfo(null, volume);
    } else {
      if (volume.tbl) { // recentVolumes just sends us a tbl string
        volume = volume.tbl;
      }
        const index = this.recentVolumes.findIndex((item) => {
          return item === volume;
        });
        if (index > -1) {
          this.recentVolumes.splice(index, 1);
        }
        this.recentVolumes.unshift(volume);
        this.dbLocalSettingsService.setLocalSetting('recentVolumes', this.recentVolumes);
        this.closeModal(false, volume);
    }
  }

  clearRecentVolumes() {
    this.recentVolumes = [];
    this.dbLocalSettingsService.setLocalSetting('recentVolumes', this.recentVolumes);
  }

  getInfo($event: Event, volume: Volume) {
    if ($event) {
      $event.stopPropagation();
    }
    this.libraryDetail(volume);
  }

  public download(volume: Volume) {
    // console.log('*** download', volume.tbl);
    if (this.downloadState.queue.indexOf(volume.tbl) === -1) {
      this.downloadService.addFile(volume.tbl);
    } else {
      this.downloadService.removeFile(volume.tbl);
    }
  }

  public sortVolumes(sortBy: string) {
    // console.log('sortVolumes', sortBy);
    this.library[this.librarySettings.volumeClass].installed.sort(
      firstBy(function (v1, v2) { return v1.installed; }, -1)
      .thenBy('purchased', -1)
      .thenBy(sortBy)
    );
    this.library[this.librarySettings.volumeClass].purchased.sort(
      firstBy(function (v1, v2) { return v1.installed; }, -1)
      .thenBy('purchased', -1)
      .thenBy(sortBy)
    );
    this.library[this.librarySettings.volumeClass].available.sort(
      firstBy(function (v1, v2) { return v1.installed; }, -1)
      .thenBy('purchased', -1)
      .thenBy(sortBy)
    );

    // installed desc, purchased desc,
  }



}

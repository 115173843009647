import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { DeviceService } from './device.service';
import { User } from '../interfaces/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User = null;
  public userChanged = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private deviceService: DeviceService,
    private storage: Storage
  ) {
    this.loadUser()
    .then((user) => {
      // console.log('user loaded', user);
    }).catch((err) => {
      console.error('error loading user', err);
    });
  }

  loadUser() {
    return new Promise((resolve, reject) => {
      this.storage.get('user')
      .then((user) => {
        if (this.user !== user) {
          this.userChanged.next(this.user);
        }
      if (user !== null) {
          this.user = user;
      }
      resolve(this.user);
      }).catch((tokenError) => {
        console.error('tokenError', tokenError);
        reject(tokenError);
      });
    });
  }

  public getUser() {
    return new Promise((resolve, reject) => {
      if (this.user !== null) {
        resolve(this.user);
      } else {
        // resolve(this.user);
        this.storage.get('user')
        .then((user) => {
          if (this.user !== user) {
            this.userChanged.next(this.user);
          }
          if (user) {
            this.user = user;
            resolve(user);
          } else {
            this.user = null;
            resolve(null);
          }          
        }).catch((err) => {
          console.log('getUser error', err);
          reject(err);
        });
      }
    });
  }
 
  logout() {
    if (this.user !== null) {
      this.user = null;
      this.userChanged.next(this.user);
      this.storage.set('user', this.user);
    }
  }
  loginWithEmail(email: string, password: string) {
    return new Promise((resolve, reject) => {
      const url = 'https://mx.mantisbible.com/api/login.loginWithEmail';
      const body = {
        email: email,
        password: password
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        console.log('response', response);
        if (response.status === 0) {
          this.user = response.user;
          this.user.token = response.token;
          this.userChanged.next(this.user);
          this.storage.set('user', this.user);
          resolve(true);
        } else {
          reject(response);
        }

      }).catch((err) => {
        console.error('err', err);
        reject(err);
      });
    });
  }
  public createAccountWithEmail(
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) {
    return new Promise((resolve, reject) => {
      const url = 'https://mx.mantisbible.com/api/login.createAccountWithEmail';
      const body = {
        data: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          mobilephone: ''
        }
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        console.log('response', response);
        /*
        onRegister error 
        {status: -1, 
          errors: Array(1), 
          email: "markb+mx01@mantisbible.com"}
          email: "markb+mx01@mantisbible.com"errors: 
          Array(1)0: "email not verified"
          length: 1__proto__: Array(0)status: -1__proto__: Objectconstructor: ƒ Object()hasOwnProperty: ƒ hasOwnProperty()isPrototypeOf: ƒ isPrototypeOf()propertyIsEnumerable: ƒ propertyIsEnumerable()toLocaleString: ƒ toLocaleString()toString: ƒ ()valueOf: ƒ valueOf()__defineGetter__: ƒ __defineGetter__()__defineSetter__: ƒ __defineSetter__()__lookupGetter__: ƒ __lookupGetter__()__lookupSetter__: ƒ __lookupSetter__()get __proto__: ƒ __proto__()set __proto__: ƒ __proto__()
        */
        if (response.status === 0 ||
            (response.status === -1 &&
              response.errors &&
              response.errors.length > 0 &&
              response.errors[0] === 'email not verified')) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch((err) => {
        console.error('err', err);
        reject(err);
      });
    });
  }
  public resetPassword(
    email: string
  ) {
    return new Promise((resolve, reject) => {
      const url = 'https://mx.mantisbible.com/api/login.resetPassword';
      const body = {
          email: email.toLowerCase()
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        console.log('response', response);
        if (response.status === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch((err) => {
        console.error('err', err);
        reject(err);
      });
    });
  }
}
/*
status: 0
token: "..."
user:
  roles: ["public"]
  firstname: ""
  lastname: ""
  mobilephone: ""
  email: ""
  createdAt: 0
  id: "00000000-0000-0000-0000-000000000000"
  expiresAt: 0
  verifications:
    email:
      data: ""
      verified: 0
    mobilephone:
      verified: 0
*/

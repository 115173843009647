import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public uuid = null;

  constructor(private storage: Storage, private utilsService: UtilsService) { }

  public getuuid(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.uuid !== null) {
        // console.log('UUID: ' + this.uuid);
        resolve(this.uuid);
      } else {
        this.storage.get('uuid')
        .then((uuid) => {
          if (uuid === null) {
            const newID = this.utilsService.guid();
            this.storage.set('uuid', newID)
            .then(() => {
              this.uuid = newID;
              resolve(newID);
            }).catch((saveError) => {
              console.error('error saving new uuid', saveError);
              reject(saveError);
            });
          } else {
            this.uuid = uuid;
            // console.log('UUID: ' + this.uuid);
            resolve(uuid);
          }
        }).catch((err) => {
          console.error('error getting uuid from storage', err);
          reject(err);
        });
      }
    });
  }

}

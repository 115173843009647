import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LongPressService {
  public press = new BehaviorSubject<[object, string]>(null);
  constructor() { }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringbuilderService {
  
  constructor() { }

  public builder(initStr): any {
    let buffer = [];
    const obj: any = {};
    if (initStr) {
      buffer.push(initStr);
    }
    obj.append = function (str) {
      buffer.push(str);
    };
    obj.clear = function () {
      buffer = null;
      buffer = [];
    };
    obj.insert = function (index, str) {
      if (buffer[index]) {
        buffer[index] = str;
      } else {
        return false;
      }
    };
    obj.replace = function (find, replace) {
      let exp;
      if (typeof find == 'object') {
        exp = find;
      } else {
        exp = new RegExp(find, 'gm');
      }
      for (let i = 0, j = buffer.length; i < j; i++) {
        try {
          if (typeof buffer[i] == 'object') {
            buffer[i] = buffer[i].toString();
          }
          buffer[i] = buffer[i].replace(exp, replace);
        } catch (bufferError) {
          console.log('********************************');
          console.log('*** bufferError             ****');
          console.log('********************************');
          console.log(bufferError);
          console.log('i:' + i);
          console.log(buffer[i]);
          console.log(buffer[i].toString());
          console.log('********************************');
        }
      }
    };
    // replaceSequential method:
    // 			does a global search and replace just like "replace"
    // 			but appends an incrementing digit onto the replacement value
    //      for each result found
    //      for example:
    //      "I saw an item, then another item, and then a third item."
    //      replaceSequential("item", "thing")
    //      "I saw an thing0, then another thing1, and then a third thing2."
    obj.replaceSequential = function (find, replace) {
      for (let i = 0; i < buffer.length; i++) {
        const exp = new RegExp(find, 'gm');
        buffer[i] = buffer[i].replace(exp, replace + i);
      }
    };
    obj.remove = function () {
      if (typeof arguments[0] == 'string') {
        for (let i = 0; i < buffer.length; i++) {
          if (arguments[0] == buffer[i]) {
            buffer.splice(i, 1);
          }
        }
      } else {
        const index = arguments[0];
        const length = (arguments.length > 1) ? arguments[1] : 1;
        buffer.splice(index, length);
      }
    };
    obj.length = function () {
      return buffer.length;
    };
    obj.toString = function () {
      return buffer.join('');
      /*
                                  var str = "";
                                  for(var i=0;i<buffer.length;i++)
                                  {
                                  str += buffer[i];
                                }
                                return str;
                                */
    };
    return obj;
  }

  
}


import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
    console.log('******* PIPE bypass ***********');
    console.log('value', value);
    console.log('********************************');
    console.log(this.sanitized.bypassSecurityTrustHtml(value));
    console.log('********************************');
    const newValue = this.sanitized.bypassSecurityTrustHtml(value);
    console.log('newValue', newValue);
    console.log('********************************');
    return newValue;
  }
}


import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Volume } from '../../interfaces/volume';
import { Platform } from '@ionic/angular';
import { AlertService } from '../../services/alert.service';
import { LibraryService } from '../../services/library.service';
@Component({
  selector: 'app-library-detail',
  templateUrl: './library-detail.page.html',
  styleUrls: ['./library-detail.page.scss'],
})
export class LibraryDetailPage implements OnInit {
  public volume: Volume;
  public isCordova = false;
  public isFavorite = false;
  public pricing: any = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    private alertService: AlertService,
    private libraryService: LibraryService
  ) {
    this.isCordova = this.platform.is('cordova');
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    console.log('** ionViewWillEnter');
    console.log('** this.navParams', this.navParams);
    if (this.navParams.data && this.navParams.data.volume) {
      this.volume = this.navParams.data.volume;
      this.isFavorite = this.libraryService.isFavorite(this.volume);
      try {
        this.pricing = this.libraryService.prices.filter((item) => item.tbl === this.volume.tbl)[0];
      } catch (err) {
        this.pricing = null;
      }
      console.log('*** this.pricing', this.pricing);
    } else {
      console.error('Error - volume was not passed.');
      this.closeModal(false);
    }
    // this.volume = this.navParams.data.volume;
  }

  async closeModal(deleteThisVolume = false) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      { deleteThisVolume: deleteThisVolume, volume: this.volume }
    );
  }

  public addToFavorites(item) {
    this.libraryService.addToFavorites(item);
  }
  public removeFromFavorites(item) {
    this.libraryService.removeFromFavorites(item);
  }
  public toggleFavorite(item) {
    console.log('calling toggleFavorite', item);
    if (this.libraryService.isFavorite(item)) {
      this.libraryService.removeFromFavorites(item);
      this.isFavorite = false;
    } else {
      this.libraryService.addToFavorites(item);
      this.isFavorite = true;
    }
  }

  deleteThisVolume() {
    this.alertService.confirm(
      `Delete ${this.volume.tbl.toUpperCase()}`,
      `Are you sure you want to delete ${this.volume.name} from your local device?  (You can always download it again later.)`,
      'DELETE', () => {
        this.closeModal(true);
      });
  }
  
  public getCategoryTitle(category) {
    let retval = '';
    switch (category.toLowerCase()) {
      case 'bible':
        retval = 'Bibles';
        break;
      case 'comm':
        retval = 'Commentaries';
        break;
      case 'media':
        retval = 'Media';
        break;
      case 'dict':
        retval = 'Dictionaries';
        break;
      case 'book':
        retval = 'Books';
        break;
      default:
        break;
    }
    return retval;
  }

  purchase(volume) {
    // console.log('*** purchase', volume);
    this.alertService.alert('Coming Soon', 'This feature is coming soon.');
  }


}

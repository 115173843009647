import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { DbService } from './db.service';
import { User } from '../interfaces/user';
import { Volume } from '../interfaces/volume';
import { DbLocalSettingsService } from './db-local-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  public volumeClasses = ['BIBLE', 'COMM', 'MEDIA', 'DICT', 'BOOK'];
  public volumes: Volume[];
  public prices: any;
  public favorites = {
    'BIBLE': [],
    'COMM': [],
    'MEDIA': [],
    'DICT': [],
    'BOOK': []
  };

  constructor(
    private userService: UserService,
    private db: DbService,
    private dbLocalSettingsService: DbLocalSettingsService
  ) {
    this.getBookshelf();
    this.loadFavorites();
    this.getPrices();
  }

  public getStrongsDictionaries(testament: string) {
    const dictionaries = this.volumes.filter((vol) => {
      if (testament === 'OT') {
        return ((vol.class === 'STRONGS39' || vol.class === 'STRONGS') && vol.purchased === 'Y');
      } else { // 'NT'
        return ((vol.class === 'STRONGS27' || vol.class === 'STRONGS') && vol.purchased === 'Y');
      }
    }).map((vol) => vol.tbl);
    return dictionaries;
  }

  public loadFavorites() {
    return new Promise((resolve, reject) => {
      this.dbLocalSettingsService.getLocalSetting('favorites')
      .then((favorites: any) => {
        // console.log('loadFavorites got', favorites);
        if (favorites) {
          this.favorites = favorites;
          resolve();
        } else {
          resolve();
        }
      }).catch((err) => {
        console.error('error loading favorites', err);
        reject(err);
      });
    });
  }

  saveFavorites() {
    this.dbLocalSettingsService.setLocalSetting('favorites', this.favorites)
    .catch((err) => {
      console.error('error saving favorites', err);
    });
  }

  public getBookshelf(forceReload = false) {
    return new Promise((resolve, reject) => {
      if (!forceReload && this.volumes && this.volumes.length > 0) {
        resolve (this.volumes);
      } else {
        this.userService.getUser()
        .then((user: User) => {
          // console.log('*** getBookshelf: user', user);
          this.db.getBookshelf(user === null ? 'anonymous' : user.id)
            .then((result) => {
              // console.log('*** getBookshelf result', result);
              // console.log(JSON.stringify(result).length);
              this.volumes = result;
              resolve(this.volumes);
            }).catch((err) => {
              console.error('getBookshelf error', err);
              reject(err);
            });
        }).catch((err) => {
          console.error('error getting current user');
          reject(err);
        });
      }
    });
  }

  public getPrices(forceReload = false) {
    return new Promise((resolve, reject) => {
      if (!forceReload && this.prices && this.prices.length > 0) {
        resolve (this.prices);
      } else {
        this.db.getPrices()
          .then((result: any) => {
            // console.log('*** getBookshelf result', result);
            // console.log(JSON.stringify(result).length);
            this.prices = result; // .data;
            console.log('** this.prices', this.prices);
            resolve(this.prices);
          }).catch((err) => {
            console.error('getBookshelf error', err);
            reject(err);
          });
      }
    });
  }

  public getInstalledVolumes(volumeClass: string) {
    return this.volumes.filter((vol) => {
      return (vol.volumeClass === volumeClass && vol.installed === 'Y');
    }).map(vol => vol.tbl);
  }

  public getFavoriteVolumes(volumeClass: string): Volume[] {
    const returnArray: Volume[] = [];
    for (let i = 0; i < this.favorites[volumeClass].length; i++) {
      const tbl = this.favorites[volumeClass][i];
      const index = this.volumes.map(item => item.tbl).indexOf(tbl);
      returnArray.push(this.volumes[index]);
    }
    return returnArray;
  }

  public getNonFavoriteVolumes(volumeClass: string): Volume[] {
    return this.volumes.filter((vol) => {
      return (vol.volumeClass === volumeClass && this.favorites[volumeClass].indexOf(vol.tbl) === -1);
    }); // .map(vol => vol.tbl);
  }

  public getFavorites(volumeClass?: string) {
    if (volumeClass) {
      return this.favorites[volumeClass];
    } else {
      return this.favorites;
    }
  }

  public toggleFavorite(item) {
    if (this.isFavorite(item)) {
      this.removeFromFavorites(item);
    } else {
      this.addToFavorites(item);
    }
  }

  public addToFavorites(item: any, addToBeginning?: boolean) {
    // console.log('addToFavorites', item);
    // console.log('favorites', this.favorites);
    let vol;
    if (typeof item === 'string') {
      vol = this.volumes.filter((v) => {
        return v.tbl === item;
      })[0];
      // console.log('addToFavorites converted to vol', vol);
    } else {
      vol = item;
    }
    // const index = this.favorites[vol.volumeClass].indexOf(vol.tbl);
    this.removeFromFavorites(vol); // remove it first if it's already here
    console.log('addToBeginning', addToBeginning);
    if (addToBeginning) {
      console.log('add to beginning of favorites');
      this.favorites[vol.volumeClass].unshift(vol.tbl);
    } else {
      console.log('add to end of favorites');
      console.log(this.favorites);
      this.favorites[vol.volumeClass].push(vol.tbl);
      console.log(this.favorites);
    }
    this.saveFavorites();
  }

  public removeFromFavorites(item: any) {
    let vol;
    if (typeof item === 'string') {
      vol = this.volumes.filter((v) => {
        return v.tbl === item;
      })[0];
    } else {
      vol = item;
    }
    const index = this.favorites[vol.volumeClass].indexOf(vol.tbl);
    if (index > -1) {
      this.favorites[vol.volumeClass].splice(index, 1);
    }
    this.saveFavorites();
  }

  public isFavorite(item) {
    // console.log('** isFavorite', item);
    if (!this.favorites) {
      return false;
    }
    if (typeof item === 'string') { // we just got a table code / id
      return (
        this.favorites.BIBLE.indexOf(item.toLowerCase()) > -1 ||
        this.favorites.COMM.indexOf(item.toLowerCase()) > -1 ||
        this.favorites.MEDIA.indexOf(item.toLowerCase()) > -1 ||
        this.favorites.DICT.indexOf(item.toLowerCase()) > -1 ||
        this.favorites.BOOK.indexOf(item.toLowerCase()) > -1
        );
    } else { // we got a whole volume, yay
      // console.log('item.volumeClass', item.volumeClass);
      // console.log('item.tbl', item.tbl);
      return (this.favorites[item.volumeClass].indexOf(item.tbl) > -1);
    }
  }
  
  public getCategoryTitle(category) {
    let retval = '';
    switch (category.toLowerCase()) {
      case 'bible':
        retval = 'Bibles';
        break;
      case 'comm':
        retval = 'Commentaries';
        break;
      case 'media':
        retval = 'Media';
        break;
      case 'dict':
        retval = 'Dictionaries';
        break;
      case 'book':
        retval = 'Books';
        break;
      default:
        break;
    }
    return retval;
  }



}

import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
// import { Router } from '@angular/router';

import { MenuController, IonSlides } from '@ionic/angular';

import { DbLocalSettingsService } from '../../../services/db-local-settings.service';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tutorial-action-bar',
  templateUrl: './tutorial-action-bar.page.html',
  styleUrls: ['./tutorial-action-bar.page.scss'],
})
export class TutorialActionBarPage {
  showSkip = true;

  @ViewChild('slides', { static: true }) slides: IonSlides;

  constructor(
    // public menu: MenuController,
    // public router: Router,
    public dbLocalSettingsService: DbLocalSettingsService,
    public modalController: ModalController
  ) {
    this.dbLocalSettingsService.getLocalSetting('tutorialsCompleted')
    .then((tutorialsCompleted) => {
      if (tutorialsCompleted) {
        tutorialsCompleted['tutorial-action-bar'] = +new Date();
      } else {
        tutorialsCompleted = {
          'tutorial-action-bar': +new Date()
        };
      }
      this.dbLocalSettingsService.setLocalSetting('tutorialsCompleted', tutorialsCompleted)
      .catch((err) => {
        console.error('could not save tutorialsCompleted to local storage', err);
      });
    }).catch((err) => {
      console.error('could not load tutorialsCompleted from local storage', err);
    });

  }

  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { key: 'value' }
    );
  }


  onSlideChangeStart(event) {
    event.target.isEnd().then(isEnd => {
      this.showSkip = !isEnd;
    });
  }

  public next() {
    this.slides.slideNext();
  }

  ionViewWillEnter() {
    /*
    this.dbLocalSettingsService.getLocalSetting('tutorial-action-bar').then((res: any) => {
      if (res === true) {
        this.router.navigateByUrl('/app/tabs/read', { replaceUrl: true });
      }
    });

    this.menu.enable(false);
    */
  }

  ionViewDidLeave() {
    // enable the root left menu when leaving the tutorial page
    // this.menu.enable(true);
  }
}

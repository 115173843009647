import { Component, OnInit } from '@angular/core';
import { VolinfoService } from '../../services/volinfo.service';
import { PopoverController } from '@ionic/angular';

import { PopoverPage } from '../bcv-popover/bcv-popover';
import { DbService } from '../../services/db.service';
import { ModalController, NavParams } from '@ionic/angular';

import { DbLocalSettingsService } from '../../services/db-local-settings.service';
import { LongPressService } from '../../services/long-press.service';
@Component({
  selector: 'app-bcv',
  templateUrl: './bcv.page.html',
  styleUrls: ['./bcv.page.scss'],
})
export class BcvPage implements OnInit {
  public dark = false;
  public vol;
  public currentBook;
  public currentChapter;
  public currentVerse;
  public books = [];
  public chapters = [];
  public verses = [];
  public colors = {};
  public colorGroups = [];
  public title = 'Choose Book:';
  public chosenBook = null;
  public chosenChapter = null;
  public chosenVerse = null;
  public displayFormat = 'FormatLargeButtons';
  private subscription;

  constructor(
      public popoverCtrl: PopoverController,
      private volinfoService: VolinfoService,
      private db: DbService,
      private modalController: ModalController,
      private navParams: NavParams,
      private dbLocalSettingsService: DbLocalSettingsService,
      private longPressService: LongPressService
    ) { }

  ngOnInit() {
    this.initColors();
    // console.log('*** bcv: longPressService.press.subscribe');
    this.subscription = this.longPressService.press.subscribe((press: any) => {
      if (press && press[0] && press[1] === 'bcv') {
        let id;
        if (press[0] && press[0].target) {
          const target: any = press[0].target;
          if (target.id) {
            // console.log('bcv long press:', target.id);
            id = target.id;
          } else if (target.parentElement.id) {
            // console.log('bcv long press:', target.parentElement.id);
            id = target.parentElement.id;
          } else {
            // console.log('bcv long press ignored', target);
          }
        }
        if (id) {
          // console.log('PROCESSING ID', id);
          switch (id.substr(0, 1)) {
            case 'b':
              this.chosenBook = {book: id.substr(1)};
              this.chosenChapter = '1';
              break;
            case 'c':
              this.chosenChapter = id.substr(1);
              this.chosenVerse = '1';
              break;
            case 'v':
              this.chosenVerse = id.substr(1);
              break;
          }
          this.closeModal(false);
        }
      }
    });
  }

  async presentPopover(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: PopoverPage,
      event
    });
    popover.onDidDismiss().then((result: any) => {
      if (result && result.data) {
        switch (result.data) {
          case 'FormatSmallButtons':
            this.displayFormat = 'FormatSmallButtons';
            break;
          case 'FormatLargeButtons':
            this.displayFormat = 'FormatLargeButtons';
            break;
          case 'FormatScrollingList':
            this.displayFormat = 'FormatScrollingList';
            break;
          default:
            console.error('unknown result from bcv popover', result);
            break;
        }
        this.dbLocalSettingsService.setLocalSetting('bcvDisplayFormat', this.displayFormat)
        .catch((err) => {
          console.error('error saving displayFormat to local settings', err);
        });
      }
    }).catch((err) => {
      console.error('library popover onDidDismiss error', err);
    });
    await popover.present();
  }

  ionViewWillEnter() {
    console.log('** ionViewWillEnter');
    window.name = 'bcv';
    this.chosenBook = null;
    this.chosenChapter = null;
    this.chosenVerse = null;
    this.currentBook = null;
    this.currentBook = null;
    this.currentVerse = null;
    this.dbLocalSettingsService.getLocalSetting('bcvDisplayFormat')
    .then((bcvDisplayFormat: any) => {
      if (bcvDisplayFormat){
        this.displayFormat = bcvDisplayFormat;
      }
    }).catch((err) => {
      console.error('could not get bcvDisplayFormat from local settings', err);
    });
    this.dark = this.navParams.data.dark;
    this.vol = this.navParams.data.vol;
    this.currentBook = this.navParams.data.book.toString();
    this.currentChapter = this.navParams.data.chapter.toString();
    if (this.navParams.data.verse) {
      this.currentVerse = this.navParams.data.verse.toString();
    } else {
      this.currentVerse = -9999; // not set
    }

    if (!this.vol || !this.volinfoService.volinfo[this.vol]) {
      this.closeModal();
    } else {
      this.books = this.volinfoService.volinfo[this.vol].books;
      if (this.navParams.data.initialBook && this.navParams.data.initialBook !== null) {
        this.chooseBook(this.navParams.data.initialBook);
      }
    }
  }
  ionViewDidEnter() {
    // console.log(document.getElementById('bookChooser'));
  }
  ionViewDidLeave() {
    // console.log('** ionViewDidLeave');
  }

  async closeModal(cancel?: boolean) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    // console.log('this.chosenBook.book', this.chosenBook.book, typeof this.chosenBook.book);
    // console.log('this.chosenChapter', this.chosenChapter, typeof this.chosenChapter);
    // console.log('this.chosenVerse', this.chosenVerse, typeof this.chosenVerse);
    const b = (this.chosenBook && this.chosenBook.book ? parseInt(this.chosenBook.book, 10) : null);
    const c = (this.chosenChapter ? parseInt(this.chosenChapter, 10) : null);
    const v = (this.chosenVerse ? parseInt(this.chosenVerse, 10) : null);

    await this.modalController.dismiss(
      cancel ? null : {
        vol: this.vol,
        book: b,
        chapter: c,
        verse: v
      }
    );
  }

  back() {
    if (this.chosenVerse) {
      this.chosenVerse = null;
      this.verses = [];
    } else if (this.chosenChapter) {
      this.chosenChapter = null;
      this.chapters = [];
      this.verses = [];
      this.title = `${this.chosenBook.name} Chapter:`;
      this.chooseBook(this.chosenBook);
    } else if (this.chosenBook) {
      this.chosenBook = null;
      this.chapters = [];
      this.verses = [];
      this.title = 'Choose Book:';
    }

  }

  chooseBook(book) {
    this.chosenBook = book;
    this.db.listChapters(this.vol, parseInt(book.book, 10))
    .then((result) => {
      this.title = `${this.chosenBook.name} Chapter:`;
      this.chapters = result.rows;
    }).catch((err) => {
      console.error('chooseBook listChapter error', err);
    });
  }
  chooseChapter(chapter) {
    this.chosenChapter = chapter.chapter;
    this.db.listVerses(this.vol, parseInt(this.chosenBook.book, 10), parseInt(this.chosenChapter, 10))
    .then((result) => {
      this.title = `${this.chosenBook.name} ${this.chosenChapter} Verse:`;
      this.verses = result.rows;
    }).catch((err) => {
      console.error('chooseBook listVerses error', err);
    });
  }
  chooseVerse(verse) {
    this.chosenVerse = verse.verse;
    this.closeModal();
  }
  public initColorGroups() {
    this.colorGroups[0] = 'yellow';
    this.colorGroups[1] = 'green';
    this.colorGroups[2] = 'pink';
    this.colorGroups[3] = 'blue';
    this.colorGroups[4] = 'orange';
    this.colorGroups[5] = 'cyan';
    this.colorGroups[6] = 'tan';
    this.colorGroups[7] = 'purple';
    this.colorGroups[8] = 'gray';
    this.colorGroups[9] = 'white';
  }
  public initColors() {
    this.initColorGroups();
    this.colors['1'] = this.colorGroups[0];
    this.colors['2'] = this.colorGroups[0];
    this.colors['3'] = this.colorGroups[0];
    this.colors['4'] = this.colorGroups[0];
    this.colors['5'] = this.colorGroups[0];
    this.colors['6'] = this.colorGroups[1];
    this.colors['7'] = this.colorGroups[1];
    this.colors['8'] = this.colorGroups[1];
    this.colors['9'] = this.colorGroups[1];
    this.colors['10'] = this.colorGroups[1];
    this.colors['11'] = this.colorGroups[1];
    this.colors['12'] = this.colorGroups[1];
    this.colors['13'] = this.colorGroups[1];
    this.colors['14'] = this.colorGroups[1];
    this.colors['15'] = this.colorGroups[1];
    this.colors['16'] = this.colorGroups[1];
    this.colors['17'] = this.colorGroups[1];
    this.colors['18'] = this.colorGroups[2];
    this.colors['19'] = this.colorGroups[2];
    this.colors['20'] = this.colorGroups[2];
    this.colors['21'] = this.colorGroups[2];
    this.colors['22'] = this.colorGroups[2];
    this.colors['23'] = this.colorGroups[3];
    this.colors['24'] = this.colorGroups[3];
    this.colors['25'] = this.colorGroups[2];
    this.colors['26'] = this.colorGroups[3];
    this.colors['27'] = this.colorGroups[3];
    this.colors['28'] = this.colorGroups[4];
    this.colors['29'] = this.colorGroups[4];
    this.colors['30'] = this.colorGroups[4];
    this.colors['31'] = this.colorGroups[4];
    this.colors['32'] = this.colorGroups[4];
    this.colors['33'] = this.colorGroups[4];
    this.colors['34'] = this.colorGroups[4];
    this.colors['35'] = this.colorGroups[4];
    this.colors['36'] = this.colorGroups[4];
    this.colors['37'] = this.colorGroups[4];
    this.colors['38'] = this.colorGroups[4];
    this.colors['39'] = this.colorGroups[4];

    this.colors['40'] = this.colorGroups[5];
    this.colors['41'] = this.colorGroups[5];
    this.colors['42'] = this.colorGroups[5];
    this.colors['43'] = this.colorGroups[5];
    this.colors['44'] = this.colorGroups[6];
    this.colors['45'] = this.colorGroups[7];
    this.colors['46'] = this.colorGroups[7];
    this.colors['47'] = this.colorGroups[7];
    this.colors['48'] = this.colorGroups[7];
    this.colors['49'] = this.colorGroups[7];
    this.colors['50'] = this.colorGroups[7];
    this.colors['51'] = this.colorGroups[7];
    this.colors['52'] = this.colorGroups[7];
    this.colors['53'] = this.colorGroups[7];
    this.colors['54'] = this.colorGroups[7];
    this.colors['55'] = this.colorGroups[7];
    this.colors['56'] = this.colorGroups[7];
    this.colors['57'] = this.colorGroups[7];
    this.colors['58'] = this.colorGroups[8];
    this.colors['59'] = this.colorGroups[8];
    this.colors['60'] = this.colorGroups[8];
    this.colors['61'] = this.colorGroups[8];
    this.colors['62'] = this.colorGroups[8];
    this.colors['63'] = this.colorGroups[8];
    this.colors['64'] = this.colorGroups[8];
    this.colors['65'] = this.colorGroups[8];
    this.colors['66'] = this.colorGroups[9];

  }

  

}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SelectItemFromListPage } from '../select-item-from-list/select-item-from-list.page';
// import { DbLocalSettingsService } from '../../services/db-local-settings.service';
import { DisplaySettings } from '../../interfaces/display-settings';
import { DisplaySettingsService } from '../../services/display-settings.service';

import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-display-settings',
  templateUrl: './display-settings.page.html',
  styleUrls: ['./display-settings.page.scss'],
})
export class DisplaySettingsPage implements OnInit {
  public displaySettings: DisplaySettings;

  constructor(
    private modalController: ModalController,
    // private navParams: NavParams,
    // private dbLocalSettingsService: DbLocalSettingsService,
    private displaySettingsService: DisplaySettingsService
  ) { }

  ngOnInit() {
    /*
    this.displaySettingsService.displaySettings.subscribe((displaySettings: DisplaySettings) => {
      this.displaySettings = displaySettings;
    });
    */
  }


  ionViewWillEnter() {
    this.displaySettings = this.displaySettingsService.displaySettings;
    console.log('** initial this.displaySettings', this.displaySettings);
  }

  ionViewWillLeave() {
    console.log('*** ionViewWillLeave()');
    this.displaySettingsService.update(this.displaySettings, true);
  }


  async closeModal() {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    console.log('*** closeModal', this.displaySettings);
    // this.displaySettingsService.update(this.displaySettings, true);
    await this.modalController.dismiss(
      // { displaySettings: this.displaySettings }
    );
  }

  async selectItemFromList(obj: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: SelectItemFromListPage,
        componentProps: obj
      });

      modal.onDidDismiss().then((dataReturned: any) => {
        if (dataReturned && dataReturned.data) {
          console.log('library dataReturned', dataReturned);
          const data = dataReturned.data;
          console.log('returned data:', data);
        }
        resolve(dataReturned);
      });
      await modal.present();
    });
  }

  getFontSizes() {
    const items = [];
    for (let i = 6; i < 41; i++) {
      items.push({ title: `${i} pt` });
    }
    for (let i = 45; i < 91; i += 5) {
      items.push({ title: `${i} pt` });
    }
    return items;
  }
  selectFontSize() {
    const items = this.getFontSizes();
    this.selectItemFromList({ title: 'Font Size:', id: 'point-sizes', items: items})
    .then((result) => {
      // console.log('selectItemFromList result', result);
      if (result.data.selectedItem && result.data.selectedItem.title) {
        this.displaySettings['--font-size'] = result.data.selectedItem.title.replace(/ /g, '');
      }
    });
  }

  getMarginSizes() {
    const items = [];
    for (let i = 0; i < 41; i++) {
      items.push({ title: `${i} pt` });
    }
    for (let i = 45; i < 121; i += 5) {
      items.push({ title: `${i} pt` });
    }
    return items;
  }

  selectMarginSize(area) {
    const items = this.getMarginSizes();
    this.selectItemFromList({ title: `${area.substr(0, 1).toUpperCase()}${area.substr(1)} Margin Size:`, id: `point-sizes`, items: items})
    .then((result) => {
      console.log('selectItemFromList result', result);
      if (result.data.selectedItem && result.data.selectedItem.title) {
        this.displaySettings[`--display-margin-${area}`] = result.data.selectedItem.title.replace(/ /g, '');
      }
    });
  }

  selectVerseMenuButtonFontSize() {
    const items = this.getFontSizes();
    this.selectItemFromList({ title: 'Verse Buttons Font Size:', id: 'font-size', items: items})
    .then((result) => {
      console.log('selectItemFromList result', result);
      if (result.data.selectedItem && result.data.selectedItem.title) {
        this.displaySettings['--verse-menu-button-font-size'] = result.data.selectedItem.title.replace(/ /g, '');
      }
    });
  }

  changeComplete($event) { // main-background-color
    console.log('changeComplete', $event);
  }
  handleChange($event: ColorEvent) {
    console.log($event.color);
  }

}

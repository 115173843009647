import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { LibraryService } from '../../services/library.service';
import { Volume } from '../../interfaces/volume';
import { LibraryDetailPage } from '../library-detail/library-detail.page';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-search-volumes',
  templateUrl: './search-volumes.page.html',
  styleUrls: ['./search-volumes.page.scss'],
})
export class SearchVolumesPage implements OnInit {
  @Input() volumes: any;
  public showCat = {
    'Favorite Bibles': true,
    'Other Bibles': false,
    'Favorite Commentaries': false,
    'Other Commentaries': false,
    'Favorite Media': false,
    'Other Media': false,
    'Favorite Dictionaries': false,
    'Other Dictionaries': false,
    'Favorite Books': false,
    'Other Books': false
  }
  // public volumes = [];
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private libraryService: LibraryService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { key: 'value' }
    );
  }
  getInfo($event: Event, volume: Volume) {
    $event.stopPropagation();
    this.libraryDetail(volume);
  }
  async libraryDetail(volume: Volume): Promise<any> {
    const modal = await this.modalController.create({
      component: LibraryDetailPage,
      componentProps: {
        volume: volume
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      if (dataReturned && dataReturned.data) {
        // console.log('LibraryDetailPage dataReturned', dataReturned);
        const data = dataReturned.data;
       //  console.log('LibraryDetailPage data', data);
      }
    });
    return await modal.present();
  }

  toggleItem(vol) {
    vol.selected = !vol.selected;
  }

}

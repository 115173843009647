import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { DbLocalSettingsService } from './services/db-local-settings.service';

import { InitService } from './services/init.service';

import { UserService } from './services/user.service';
import { User } from './interfaces/user';

import { DisplaySettingsService } from './services/display-settings.service';
import './utils/long-press-event.js';
import { LongPressService } from './services/long-press.service';
import { ThemeService } from './services/theme.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Read',
      url: '/app/tabs/read',
      icon: 'book-outline'
    },
    {
      title: 'Studies',
      url: '/app/tabs/studies',
      icon: 'documents-outline'
    },
    {
      title: 'Markers',
      url: '/app/tabs/markers',
      icon: 'bookmarks-outline'
    },
    {
      title: 'Search',
      url: '/app/tabs/search',
      icon: 'search-outline'
    },
    {
      title: 'History',
      url: '/app/tabs/history',
      icon: 'time-outline'
    },
    {
      title: 'Split',
      url: '/split',
      icon: 'documents-outline'
    }
  ];
  loggedIn = false;
  dark = false;
  user: User = null;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dbLocalSettingsService: DbLocalSettingsService,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private initService: InitService,
    private userService: UserService,
    private displaySettingsService: DisplaySettingsService,
    private longPressService: LongPressService,
    private themeService: ThemeService
  ) {
    this.initializeApp();
    this.userService.userChanged.subscribe((user: User) => {
      if (user !== null) {
        this.loggedIn = true;
        this.user = user;
      } else {
        this.loggedIn = false;
        this.user = null;
      }
    });
  }

  async ngOnInit() {
    // this.checkLoginStatus();
    // this.listenForLoginEvents();
    window.oncontextmenu = ($event: any) => {
      $event.preventDefault();
      // console.log('*** long-press-event', $event.srcElement.id);
      console.log('*** oncontextmenu swallowed... gulp.');
    };
    const _this = this;
    window.document.body.addEventListener('long-press', function ($event: any) {
      _this.longPressService.press.next([$event, window.name]);
      // console.log('app.component.long-press ', $event, window.location.pathname);
      if ($event.target.id === 'xxAppLogo') {
        // console.log('APP LOGO PRESSED');
        _this.menu.close();
        _this.router.navigateByUrl('/diagnostics');
      }
    });

    console.log('*** app.component.ts getLocalSetting dark');
    this.dbLocalSettingsService.getLocalSetting('dark')
      .then((darkSetting) => {
        if (darkSetting) {
          this.dark = true;
          this.themeService.setDarkMode(true);
        }
      }).catch((err) => {
        console.log('dbLocalSettingsService getLocalSetting(dark) error', err);
      });
      /*
    this.dbLocalSettingsService.getLocalSetting('displaySettings')
      .then((displaySettings) => {
        if (displaySettings) {
          this.displaySettingsService.update(displaySettings); // update all display settings
        } else {
          this.displaySettingsService.update(); // initialize display settings
        }
      }).catch((err) => {
        this.displaySettingsService.update(); // initialize display settings
        console.error('Error getting displaySettings from local settings', err);
      });
      */
    this.swUpdate.available.subscribe(async res => {


      const toast = await this.toastCtrl.create({
        header: 'Update',
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            side: 'end',
            icon: 'checkmark',
            text: 'Install',
            handler: () => {
              console.log('Install update clicked');
              this.swUpdate.activateUpdate()
                .then(() => window.location.reload());
            }
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Update cancelled');
            }
          }
        ]
      });
      toast.present();

      /*
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        // showCloseButton: true,
        position: 'bottom',
        // closeButtonText: `Reload`
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    */
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.initService.init(); // copy databases
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();  
      }
    });
  }

  logout() {
    this.userService.logout();
    this.router.navigateByUrl('/login');
  }

  openTutorial() {
    this.menu.enable(false);
    this.dbLocalSettingsService.setLocalSetting('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
  darkModeChanged() {
    this.dbLocalSettingsService.setLocalSetting('dark', this.dark)
      .then((success) => {
        this.themeService.setDarkMode(this.dark);
        console.log('dark setting saved');
      }).catch((err) => {
        console.log('could not save dark setting', err);
      });
      this.menu.close();
  }
  public closeMenu() {
    console.log('** closeMenu()');
    this.menu.close();
  }
}

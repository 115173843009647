import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DbLocalSettingsService } from '../services/db-local-settings.service';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public dark = false;
  public darkMode = new BehaviorSubject<boolean>(false);

  constructor(
    private dbLocalSettingsService: DbLocalSettingsService
  ) {
    /*
    this.dbLocalSettingsService.getLocalSetting('dark')
      .then((darkSetting: any) => {
        if (darkSetting) {
          this.darkMode.next(true);
          this.dark = true;
        } else {
          // this.darkMode.next(false); // already false, not necessary
          this.dark = false;
        }
      }).catch((err) => {
        console.log('dbLocalSettingsService getLocalSetting(dark) error', err);
      });
      */
  }
  public setDarkMode(darkMode) {
    this.darkMode.next(darkMode);
    this.dark = darkMode;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public guid() {
    const _p8 = (s?) => {
        const p = (Math.random().toString(16) + '000000000').substr(2,8);
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p ;
    };
    return _p8() + _p8(true) + _p8(true) + _p8();
  }

  public createIcon(name, id?: string, classes?: string[]): HTMLIonIconElement {
    const icon: HTMLIonIconElement = document.createElement('ion-icon');
    if (name.indexOf('/') > -1) {
      icon.src = name;
    } else {
      icon.name = name;
    }
    if (id) {
      icon.id = id;
    }
    if (classes) {
      for (let i = 0; i < classes.length; i++) {
        if (classes[i].length > 0) {
          icon.classList.add(classes[i]);
        }
      }
    }
    return icon;
  }


}

import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VolinfoService } from './volinfo.service';
// import { InitService } from '../services/init.service';
import { UserService } from '../services/user.service';
// import { SecService } from '../services/sec.service';
import { DeviceService } from '../services/device.service';
// import CryptoES from 'crypto-es';
// import CryptoES from 'crypto-es';
// import rc4 from 'crypto-js/rc4';
// import utf8 from 'crypto-js/enc-utf8'; 
import CryptoJS from 'crypto-js';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class DbshuttleService {
  public APIHOST = 'https://mxiapi.mantisbible.com';
  public dbHandles: any = {};
  public isCordova = false;
  private bookshelfCache = null;

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private sqlite: SQLite,
    private volinfoService: VolinfoService,
    // private initService: InitService,
    private userService: UserService,
    private deviceService: DeviceService
  ) {
    this.isCordova = this.platform.is('cordova');

    this.userService.userChanged.subscribe((user) => {
      if (user === null) {
        this.bookshelfCache = null;
      }
    });

    // console.log('*** window.cordova:', window.cordova);

    if (this.isCordova) {
      // this.initService.initializeDatabases();
      /*
      this.copydb('kjv')
      .then((result) => {
        console.log('*** copydb kjv', result);
      }).catch((err) => {
        console.error('*** copydb kjv error', err);
      });
      this.copydb('bookshelf')
      .then((result) => {
        console.log('*** copydb bookshelf', result);
      }).catch((err) => {
        console.error('*** copydb bookshelf error', err);
      });
      */
    }
  }

  public openVol(vol): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.openVolLocal(vol)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.openVolRemote(vol)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  // SELECT * from BOOK order by book
  openVolLocal(dbname: string): Promise<any> {
    // console.log('** openVolLocal: ' + dbname);
    return new Promise((resolve, reject) => {
      this.getKey(dbname)
        .then((dbkey: string) => {
          if (this.dbHandles[dbname]) {
            // console.log('db ' + dbname + ' handle exists');
            // console.log('this.dbHandles', this.dbHandles);
            resolve();
          } else {
            /*
            this.copydb(dbname)
            .then((copyResult) => {
              console.log('copydb ' + dbname + ' result:', copyResult);
            */
            this.sqlite.create({
              name: dbname + '.db4',
              key: dbkey,
              location: 'default'
            }).then((db: SQLiteObject) => {
              // this.databaseObj = db;
              this.dbHandles[dbname] = db;
              // console.log('Database Opened!');
              // resolve(true);
              if (dbname === 'bookshelf' || dbname === 'storage') {
                resolve({});
              } else {
                this.getSQL(dbname, 'SELECT * from BOOK order by book')
                  .then((rows) => {
                    this.getSQL('bookshelf', `select * from Volume where tbl = '${dbname}'`)
                      .then((volinfo: any) => {
                        if (volinfo.length > 0) {
                          // console.log('**** openVolLocal returning rows:', rows.length);
                          this.volinfoService.setBooks(dbname, rows);
                          this.volinfoService.setVolInfo(dbname, volinfo[0]);
                          resolve({ books: rows, volinfo: volinfo[0] });
                        } else {
                          reject('could not find item on bookshelf: ' + dbname);
                        }
                      }).catch((err) => {
                        reject(err);
                      });
                  }).catch((sqlError) => {
                    reject(sqlError);
                  });
              }
            })
              .catch((e) => {
                console.error('open error ' + JSON.stringify(e));
                reject(e);
              });
            /*
            }).catch((copyError) => {
              console.log('openVolLocal failed with copyError: ', copyError);
              reject(copyError);
            });
            */
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }
  public openVolRemote(vol): Promise<any> {
    // console.log('** openVolRemote', vol);
    return new Promise((resolve, reject) => {
      const url = `${this.APIHOST}/volinfo`;
      const body = {
        vol: vol
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            // console.log('** openVolRemote got response:', response);
            this.volinfoService.setBooks(vol, response.data.books);
            this.volinfoService.setVolInfo(vol, response.data.volinfo);
            // console.log('*** this.volinfoService.volinfo', this.volinfoService.volinfo);
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  copydb(dbname: string) {

    console.log('copying database ' + dbname);

    return new Promise((resolve, reject) => {
      this.platform.ready().then(() => {
        console.log('** platform ready');
        const success = (result) => {
          resolve(result);
        };
        const failure = (err) => {
          reject(err);
        };
        (<any>window).plugins.sqlDB.copy(dbname + '.db4', 0, success, failure);
      });
    });
  }

  public getSQL(vol: string, sql: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const rows = [];
      this.openVol(vol)
        .then(() => {
          this.dbHandles[vol].executeSql(sql, [])
            .then((res) => {
              if (res.rows.length > 0) {
                for (let i = 0; i < res.rows.length; i++) {
                  rows.push(res.rows.item(i));
                }
              }
              resolve(rows);
            })
            .catch((e) => {
              console.error('getSQL error 1' + JSON.stringify(e));
              reject(e);
            });
        }).catch((openError) => {
          console.error('getSQL error 2' + JSON.stringify(openError));
          reject(openError);
        });
    });
  }

  public sqlBatch(vol: string, sql: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openVol(vol)
        .then(() => {
          this.dbHandles[vol].sqlBatch(sql)
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              console.error('sqlBatch error 1' + JSON.stringify(e));
              reject(e);
            });
        }).catch((openError) => {
          console.error('sqlBatch open error 2' + JSON.stringify(openError));
          reject(openError);
        });
    });
  }

  public getDataRemote(vol: string, book: number, chapter: number, verse?: number, endverse?: number) {
    // console.log('getDataRremote vol, book ,chapter, verse, endverse', book, chapter, verse, endverse);
    return new Promise((resolve, reject) => {
      const url = `${this.APIHOST}/data`;
      const body = {
        vol: vol,
        b: book,
        c: chapter,
        v: verse,
        ev: endverse
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          // console.log('getDataRemote got response:', response);
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          console.log('getDataRemote got err:', err);
          reject(err);
        });
    });
  }

  public getBookshelfRemote() {
    // console.log('*** getBookshelfRemote');

    const getBookshelfFromServer = () => {
      return new Promise((resolve, reject) => {

        this.deviceService.getuuid()
          .then((uuid) => {
            const httpOptions = {
              headers: new HttpHeaders({
                'oauthToken': this.userService.user ? this.userService.user.token : 'anonymous',
                'oauthService': 'email',
              })
            };
            const url = `${this.APIHOST}/bookshelf`;
            const body = {
              uuid: uuid,
              skip: []
            };
            this.http.post(url, body, httpOptions).toPromise()
              .then((response: any) => {
                // console.log('getBookshelfRemote.getBookshelfFromServer got response:', response);
                if (response.data) {
                  this.bookshelfCache = response.data;
                  resolve(response.data);
                } else if (response.err) {
                  this.bookshelfCache = null;
                  reject(response.err);
                } else if (response.error) {
                  this.bookshelfCache = null;
                  reject(response.error);
                } else {
                  this.bookshelfCache = null;
                  reject(response);
                }
              }).catch((err) => {
                console.log('getDataRemote got err:', err);
                reject(err);
              });
          }).catch((err) => {
            console.error('Error: could not get uuid', err);
          });

      });
    };

    return new Promise((resolve, reject) => {
      if (this.bookshelfCache === null) {
        // console.log('*** getting bookshelf from server');
        getBookshelfFromServer()
          .then((result) => {
            resolve(result);
          }).catch((err) => {
            reject(err);
          });
      } else {
        // console.log('*** returned cached bookshelf'); // , this.bookshelfCache);
        resolve(this.bookshelfCache);
      }
    });
  }

  public clearBookshelfCache() {
    this.bookshelfCache = null;
  }

  public getKey(db) {
    return new Promise((resolve, reject) => {
      switch (db) {
        case 'bookshelf':
          resolve('bookshelf');
          break;
        case 'storage':
          this.deviceService.getuuid()
            .then((uuid) => {
              // console.log('UUID', uuid);
              resolve(uuid);
            }).catch((err) => {
              reject(err);
            });
          break;
        default:
          this.userService.getUser()
            .then((user: User) => {
              let userID;
              if (user === null) {
                userID = 'anonymous';
              } else {
                userID = user.id;
              }
              // console.log('** getKey got user:', user);
              // console.log(`select key from keys where user = '${(user.id ? user.id : 'anonymous')}' and vol = '${db}'`);
              this.getSQL('storage', `select key from keys where user = '${(userID)}' and vol = '${db}'`)
                .then((rows: any) => {
                  // console.log('getKey got rows', rows);
                  if (rows && rows.length >= 1) {
                    this.decryptKey(db, rows[0].key)
                      .then(function (result) {
                        resolve(result);
                      }).catch(function (err) {
                        reject('decryption error: ' + JSON.stringify(err));
                      });
                  } else {
                    reject('missing key: ' + db);
                  }
                }).catch((err) => {
                  reject(err);
                });

            }).catch((err) => {
              reject(err);
            });
          break;
      }
    });
  }

  decryptKey(item, crypted) {
    let userID;
    if (this.userService.user && this.userService.user.id) {
      userID = this.userService.user.id;
    } else {
      userID = 'anonymous';
    }
    return new Promise((resolve, reject) => {
      this.deviceService.getuuid()
        .then(function (uuid) {
          const token = CryptoJS.RC4.decrypt(crypted,
            userID +
            item +
            uuid +
            '24a8513a2482468680519a8c6d3b04d3').toString(CryptoJS.enc.Utf8);

          resolve(token);
        }).catch(function (err) {
          console.error('*** could not get uuid: ' + JSON.stringify(err));
          reject('uuid error: ' + JSON.stringify(err));
        });

    });

  }

  public updatePurchases() {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getPurchases()
          .then((purchases: any) => {
            // console.log('*** getPurchases', purchases);
            this.addKeysToStorage(purchases.data)
              .then((result) => {
                console.log('updatePurchases result: ', result);
                resolve(true);
              }).catch((err) => {
                console.error('updatePurchases error: ', err);
                reject(err);
              });
          }).catch((err) => {
            console.error('updatePurchases getPurchases error: ', err);
            reject(err);
          });
      } else {
        console.log('** skipping updatePurchases because isCordova is false');
        resolve(true);
      }
    });
  }


  getPurchases() {
    console.log('****************************************');
    console.log('***** getPurchases()                 ***');
    console.log('****************************************');
    let anonymousUser = false;

    return new Promise((resolve, reject) => {
      if (this.userService.user === null) {
        anonymousUser = true;
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'oauthToken': anonymousUser ? 'anonymous' : this.userService.user.token,
          'oauthService': 'email'
        })
      };
      this.userService.getUser()
        .then((user: User) => {
          let userID;
          if (user === null) {
            userID = 'anonymous';
          } else {
            userID = user.id;
          }
          this.getSQL('storage', `select vol from keys where user='${userID}'`)
            .then((rows) => {
              const purchasedItems = [];
              rows.map((row) => {
                purchasedItems.push(row.vol);
              });
              console.log('*** purchasedItems', purchasedItems);
              this.deviceService.getuuid()
                .then((uuid) => {
                  this.http.post('https://mx.mantisbible.com/api/user.getPurchases',
                    { uuid: uuid, skip: purchasedItems }, httpOptions).toPromise()
                    .then((purchases) => {
                      resolve(purchases);
                    }).catch((purchasesError) => {
                      console.error('*** dbShuttleService purchasesError', purchasesError);
                      reject(purchasesError);
                    });
                }).catch((getuuidError) => {
                  console.error('getuuidError', getuuidError);
                  reject(getuuidError);
                });
            }).catch((err) => {
              console.error('Error getting purchased items', err);
              reject(err);
            });
        }).catch((err) => {
          console.error('error getting user', err);
          reject(err);
        });
    });

  }

  addKeysToStorage(rows) {
    console.log('*** addKeysToStorage...', rows);
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        const statementArray = ['create table if not exists keys (user,vol,key)'];
        const volArray = [];
        statementArray.push('create unique index if not exists ix_key on keys (user,vol)');
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            // vol, key
            const sql = 'replace into keys (user,vol,key) values (\'' +
              (this.userService.user !== null ? this.userService.user.id : 'anonymous') + '\',\'' + row.vol + '\',\'' + row.key + '\')';
            statementArray.push(sql);
            volArray.push(row.vol);
          }
        } else {
          console.error('*** error -- getPurchases returned:', rows);
        }
        this.sqlBatch('storage', statementArray)
          .then((result) => {
            console.log('key insertion result', result);
            resolve(rows.length + ' keys inserted');
          }).catch((err) => {
            reject('key insertion error: ' + JSON.stringify(err));
          });
        /*
        console.log('running bookshelf sql: ');
        console.log(`update volume set purchased = 'Y' where tbl in ('${ volArray.join('\',\'') }')`);
        this.getSQL('bookshelf', `update volume set purchased = 'Y' where tbl in ('${ volArray.join('\',\'') }')`)
        .then((result) => {
          console.log('update purchases in bookshelf result', result);
        }).catch((err) => {
          console.error('error updating bookshelf volumes', err);
        });
        */
      } else {
        reject('not running in cordova environment');
      }
    });
  }


}



import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tutorial',
    pathMatch: 'full'
  },
  {
    path: 'support',
    redirectTo: '/app/tabs/support'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'read',
    loadChildren: () => import('./pages/read/read.module').then( m => m.ReadPageModule)
  },
  {
    path: 'read',
    loadChildren: () => import('./pages/read/read.module').then( m => m.ReadPageModule)
  },
  {
    path: 'studies',
    loadChildren: () => import('./pages/studies/studies.module').then( m => m.StudiesPageModule)
  },
  {
    path: 'markers',
    loadChildren: () => import('./pages/markers/markers.module').then( m => m.MarkersPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'bcv/:vol',
    loadChildren: () => import('./pages/bcv/bcv.module').then( m => m.BcvPageModule)
  },
  {
    path: 'library',
    loadChildren: () => import('./pages/library/library.module').then( m => m.LibraryPageModule)
  },
  {
    path: 'library-detail',
    loadChildren: () => import('./pages/library-detail/library-detail.module').then( m => m.LibraryDetailPageModule)
  },
  {
    path: 'display-settings',
    loadChildren: () => import('./pages/display-settings/display-settings.module').then( m => m.DisplaySettingsPageModule)
  },
  {
    path: 'select-item-from-list',
    loadChildren: () => import('./pages/select-item-from-list/select-item-from-list.module').then( m => m.SelectItemFromListPageModule)
  },
  {
    path: 'diagnostics',
    loadChildren: () => import('./pages/diagnostics/diagnostics.module').then( m => m.DiagnosticsPageModule)
  },
  {
    path: 'study-bar-settings',
    loadChildren: () => import('./pages/study-bar-settings/study-bar-settings.module').then( m => m.StudyBarSettingsPageModule)
  },
  {
    path: 'action-bar-settings',
    loadChildren: () => import('./pages/action-bar-settings/action-bar-settings.module').then( m => m.ActionBarSettingsPageModule)
  },
  {
    path: 'action-bar-quick-bookmark',
    loadChildren: () => import('./pages/action-bar-quick-bookmark/action-bar-quick-bookmark.module').then( m => m.ActionBarQuickBookmarkPageModule)
  },
  {
    path: 'tutorial-action-bar',
    loadChildren: () => import('./pages/tutorials/tutorial-action-bar/tutorial-action-bar.module').then( m => m.TutorialActionBarPageModule)
  },
  {
    path: 'action-bar-quick-highlight',
    loadChildren: () => import('./pages/action-bar-quick-highlight/action-bar-quick-highlight.module').then( m => m.ActionBarQuickHighlightPageModule)
  },
  {
    path: 'study',
    loadChildren: () => import('./pages/study/study.module').then( m => m.StudyPageModule)
  },
  {
    path: 'split',
    loadChildren: () => import('./pages/split/split.module').then( m => m.SplitPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

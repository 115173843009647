import { Injectable } from '@angular/core';
// import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DbshuttleService } from './dbshuttle.service';
import { History } from '../interfaces/history';
import { UserService } from './user.service';
import { User } from '../interfaces/user';
import { SearchService } from './search.service';
import { DeviceService } from './device.service';
import { Setting } from '../interfaces/setting';
@Injectable({
  providedIn: 'root'
})

export class DbService {

  public isCordova = false;

  constructor(
    // private sqlite: SQLite,
    private platform: Platform,
    private http: HttpClient,
    private dbshuttleService: DbshuttleService,
    private userService: UserService,
    private searchService: SearchService,
    private deviceService: DeviceService
  ) {
    this.isCordova = this.platform.is('cordova');
  }

  /*
  async postData(url: string,
    body: object,
    options: object = {}) {
    // options = { responseType: 'text' }
    return await this.http.post(url, body, options).toPromise();
  }
  async getData(url: string, options: object = {}) {
    // options = { responseType: 'text' }
    return await this.http.get(url, options).toPromise();
  }
  */

 public getNearest(book, chapter, verse, volumes) {
  return new Promise((resolve, reject) => {
    if (this.isCordova) {
      this.getNearestLocal(book, chapter, verse, volumes)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    } else {
      this.getNearestRemote(book, chapter, verse, volumes)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    }
  });
}

  /*
  getNearestEntries(vol, book, chapter, verse) {
    return new Promise((resolve, reject) => {
      const composite = ('000' + book).slice(-3) + ('000' + chapter).slice(-3) + ('000' + verse).slice(-3);

      queryDB(vol,'select book.name as name, book.abbr as abbr, book.abbr2 as abbr2, book.abbr3 as abbr3, content.book as book,content.chapter as chapter,content.verse as verse,substr(\'000\' || content.book, -3, 3) || substr(\'000\' || chapter, -3, 3) || substr(\'000\' || verse, -3, 3) as composite ' +
                'from content ' +
                'left outer join book on book.book = content.book' +
                ' where composite >= \'' + composite + '\' order by composite  limit 1')
        .then((nextverse) => {
          queryDB(vol,'select book.name as name, book.abbr as abbr, book.abbr2 as abbr2, book.abbr3 as abbr3, content.book as book,content.chapter as chapter,content.verse as verse,substr(\'000\' || content.book, -3, 3) || substr(\'000\' || chapter, -3, 3) || substr(\'000\' || verse, -3, 3) as composite ' +
          ' from content ' +
          'left outer join book on book.book = content.book' +
          ' where composite <= \'' + composite + '\' order by composite desc limit 1')
            .then((previousverse) => {
              resolve({next: nextverse, prev: previousverse});
            }).catch(function(err) {
              console.error('*** getNearestEntries previousverse error: ' + JSON.stringify(err));
              reject(err);
            });
        }).catch(function(err) {
          console.error('*** getNearestEntries nextverse error: ', err);
          reject(err);
        });
    });
  }
  */

getNearestLocal(book, chapter, verse, volumes) {
  return new Promise((resolve, reject) => {
    reject('not implemented yet');
  });
}

getNearestRemote(book, chapter, verse, volumes) {
  return new Promise((resolve, reject) => {
    const url = `${this.dbshuttleService.APIHOST}/getnearestentries`;
    const body = {
      book: book,
      chapter: chapter,
      verse: verse,
      volumes: volumes
    };
    this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        if (response.data) {
          resolve(response.data.rows);
        } else if (response.err) {
          reject(response.err);
        } else if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}


  // *** passing userID is now obsolete here ***
  public getBookshelf(userID?: string): Promise<any> {
    // console.log('db.getBookshelf userID:', userID);
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.userService.getUser()
          .then((user: User) => {
            this.getBookshelfLocal(user ? user.id : 'anonymous')
            .then((success) => {
              resolve(success);
            }).catch((failure) => {
              console.error('db service getBookshelfLocal error', failure);
              reject(failure);
            });
          }).catch((err) => {
            reject(err);
          });
      } else {
        this.dbshuttleService.getBookshelfRemote()
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            console.error('db service dbshuttleService.getBookshelfRemote error', failure);
            reject(failure);
          });
      }
    });
  }

  getBookshelfLocal(userID: string): Promise<any> {
    let sql;
    const purchasedItems = [];
    return new Promise((resolve, reject) => {
      this.dbshuttleService.openVolLocal('bookshelf')
        .then(() => {
          this.dbshuttleService.getSQL('storage', `select vol from keys where user = '${userID}'`)
            .then((rows) => {
              // console.log(rows);
              for (let i = 0; i < rows.length; i++) {
                // console.log('purchased item from storage: ', rows[i].vol);
                purchasedItems.push(rows[i].vol);
              }
              // console.log('***** BOOKSHELF purchasedItems', purchasedItems);
              sql = `SELECT * from Volume order by installed desc, purchased desc, name`;
              this.dbshuttleService.getSQL('bookshelf', sql)
                .then((volumes) => {
                  // console.log('volumes', volumes);
                  for (let j = 0; j < volumes.length; j++) {
                    if (purchasedItems.indexOf(volumes[j].tbl) > -1) {
                      volumes[j].purchased = 'Y';
                    } else {
                      volumes[j].purchased = 'N';
                    }
                  }
                  resolve(volumes);
                }).catch((sqlError) => {
                  console.error('getBookshelfLocal sqlError', sqlError);
                  reject(sqlError);
                });
            }).catch((err) => {
              console.error('getBookshelfLocal openVolLocal err', err);
              reject(err);
            });
        });
    });
  }

  public getPrices() {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/prices`;
      const body = { };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          console.error('/prices error', err);
          reject(err);
        });
    });
  }

  public getStrongsDictEntry(strongsDict, strongsNumber) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getStrongsDictEntryLocal(strongsDict, strongsNumber)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getStrongsDictEntryRemote(strongsDict, strongsNumber)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  getStrongsDictEntryLocal(strongsDict, strongsNumber) {
    return new Promise((resolve, reject) => {

      const sql = `select caption, hex(txt) as txt from CONTENT where caption = '${strongsNumber}' or caption like '${strongsNumber} %'`;
      this.dbshuttleService.getSQL(strongsDict, sql)
        .then((rows) => {
          if (rows.length > 0) {
            resolve(rows[0]);
          } else {
            resolve({});
          };
        }).catch((err) => {
          reject(err);
        });

    });
  }

  getStrongsDictEntryRemote(strongsDict, strongsNumber) {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/getstrongsdictentry`;
      const body = {
        dict: strongsDict,
        sn: strongsNumber
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data.text);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  /*

    function getGrammar(vol, lang, grammar) {
      var deferred = $q.defer();
      //console.log("select name,code,definition from GRAMMAR where language = '" + lang + "' and code in ('" + grammar.replace(/,/g,"','") + "')");
      // select * from grammar where language = 'H' and code in ('nn','pl');
      queryDB(vol,"select name,code,definition from GRAMMAR where language = '" + lang + "' and code in ('" + grammar.replace(/,/g,"','") + "')")
      .then(function(rows){
        //console.log('** getGrammar returning: ',rows);
        deferred.resolve(rows);
      }).catch(function(err){
        deferred.reject(err);
      });
      return deferred.promise;
    }
  */

 public getStrongsLex(vol, topic) {
  return new Promise((resolve, reject) => {
    if (this.isCordova) {
      this.getStrongsLexLocal(vol, topic)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    } else {
      this.getStrongsLexRemote(vol, topic)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    }
  });
}

getStrongsLexLocal(vol, topic) {
  return new Promise((resolve, reject) => {
    // "select name,code,definition from GRAMMAR where language = '" + lang + "' and code in ('" + grammar.replace(/,/g,"','") + "')"
    const sql = `select Definition as txt from STRONGSLEX where Topic = '${topic}'`;
    this.dbshuttleService.getSQL(vol, sql)
      .then((rows) => {
        if (rows.length > 0) {
          resolve({
            text: rows.length > 0 ? rows[0].txt : '',
            vol: vol,
            topic: topic
          });
        } else {
          resolve({});
        };
      }).catch((err) => {
        reject(err);
      });

  });
}
getStrongsLexRemote(vol, topic) {
  return new Promise((resolve, reject) => {
    const url = `${this.dbshuttleService.APIHOST}/getstrongslex`;
    const body = {
      vol: vol,
      topic: topic
    };
    this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        if (response.data) {
          resolve(response.data.text);
        } else if (response.err) {
          reject(response.err);
        } else if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}

public searchVolume(vol: string, query: string, matchMode: string, offset: number) {
  return new Promise((resolve, reject) => {
    if (this.isCordova) {
      this.searchVolumeLocal(vol, query, matchMode, offset)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    } else {
      this.searchVolumeRemote(vol, query, matchMode, offset)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    }
  });
}

searchVolumeLocal(vol, query, matchMode, offset) {
  return new Promise((resolve, reject) => {
    const sql = this.searchService.searchVolumeSQL(vol, query, matchMode, offset);
    this.dbshuttleService.getSQL(vol, sql)
      .then((rows) => {
        if (rows.length > 0) {
          resolve({
            rows: rows,
            vol: vol,
            query: query,
            matchMode: matchMode
          });
        } else {
          resolve({});
        };
      }).catch((err) => {
        reject(err);
      });

  });
}

searchVolumeRemote(vol, query, matchMode, offset) {
  return new Promise((resolve, reject) => {
    const url = `${this.dbshuttleService.APIHOST}/searchvolume`;
    const body = {
      vol: vol,
      query: query,
      matchMode: matchMode,
      offset: offset
    };
    this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        if (response.data) {
          resolve(response.data.rows);
        } else if (response.err) {
          reject(response.err);
        } else if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}


public getStrongsFind(vol, sn) {
  return new Promise((resolve, reject) => {
    if (this.isCordova) {
      this.getStrongsFindLocal(vol, sn)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    } else {
      this.getStrongsFindRemote(vol, sn)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    }
  });
}

getStrongsFindLocal(vol, sn) {
  return new Promise((resolve, reject) => {
    const sql =
    `select V.book,V.chapter,V.verse,BOOK.name,BOOK.abbr2 from CONTENT as V INNER JOIN FT on FT.rowid = V.rowid JOIN BOOK on BOOK.book = V.BOOK where FT match '"${sn}"' order by V.book,V.chapter,V.verse`;
    this.dbshuttleService.getSQL(vol, sql)
      .then((rows) => {
        if (rows.length > 0) {
          resolve({
            rows: rows,
            vol: vol,
            sn: sn
          });
        } else {
          resolve({});
        };
      }).catch((err) => {
        reject(err);
      });

  });
}

getStrongsFindRemote(vol, sn) {
  return new Promise((resolve, reject) => {
    const url = `${this.dbshuttleService.APIHOST}/strongsfind`;
    const body = {
      vol: vol,
      sn: sn
    };
    this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        if (response.data) {
          resolve(response.data.rows);
        } else if (response.err) {
          reject(response.err);
        } else if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}

 public getGrammar(vol, lang, grammar) {
  return new Promise((resolve, reject) => {
    if (this.isCordova) {
      this.getGrammarLocal(vol,lang, grammar)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    } else {
      this.getGrammarRemote(vol, lang, grammar)
        .then((success) => {
          resolve(success);
        }).catch((failure) => {
          reject(failure);
        });
    }
  });
}

getGrammarLocal(vol, lang, grammar) {
  return new Promise((resolve, reject) => {
    // "select name,code,definition from GRAMMAR where language = '" + lang + "' and code in ('" + grammar.replace(/,/g,"','") + "')"
    const sql = `select name,code,definition from GRAMMAR where language = '${lang}' and code in ('${grammar.replace(/,/g, '\',\'')}')`;
    this.dbshuttleService.getSQL(vol, sql)
      .then((rows) => {
        if (rows.length > 0) {
          resolve(rows);
        } else {
          resolve({});
        };
      }).catch((err) => {
        reject(err);
      });

  });
}
getGrammarRemote(vol, lang, grammar) {
  return new Promise((resolve, reject) => {
    const url = `${this.dbshuttleService.APIHOST}/getgrammar`;
    const body = {
      vol: vol,
      lang: lang,
      grammar: grammar
    };
    this.http.post(url, body, { responseType: 'json' }).toPromise()
      .then((response: any) => {
        if (response.data) {
          // response.data.text.Topic
          // response.data.text.Definition
          // console.log('getstrongs got a response', response);
          resolve(response.data.text);
        } else if (response.err) {
          reject(response.err);
        } else if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}



  public getStrongs(vol, sn, lang, grammar) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getStrongsLocal(vol, sn, lang, grammar)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getStrongsRemote(vol, sn, lang, grammar)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  getStrongsLocal(vol, sn, lang, grammar) {
    return new Promise((resolve, reject) => {
      if ('ABCDEFG'.indexOf(sn.substr(sn.length - 1, 1)) > -1) {
        sn = sn.substr(0, sn.length - 1) + sn.substr(sn.length - 1, 1).toLowerCase();
      }

      const sql = `select Topic,hex(Definition) as Definition from STRONGS where Topic = '${sn}'`;
      this.dbshuttleService.getSQL(vol, sql)
        .then((rows) => {
          if (rows.length > 0) {
            resolve(rows[0]);
          } else {
            resolve({});
          };
        }).catch((err) => {
          reject(err);
        });

    });
  }
  getStrongsRemote(vol, sn, lang, grammar) {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/getstrongs`;
      const body = {
        vol: vol,
        sn: sn,
        lang: lang,
        grammar: grammar
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            // response.data.text.Topic
            // response.data.text.Definition
            // console.log('getstrongs got a response', response);
            resolve(response.data.text);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  public getSettings() {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getSettingsLocal()
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getSettingsRemote()
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  public getSettingsRemote() {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/getsettings`;
      const body = {};
      let token;
      this.deviceService.getuuid()
      .then((uuid: string) => {
        this.userService.getUser()
        .then((user: User) => {
          if (user) {
            token = user.token;
          } else {
            token = 'anonymous.' + uuid;
          }
          // body.debug = 'Y';
          const httpOptions = {
            headers: new HttpHeaders({
              'oauthToken': token,
              'oauthService': 'email'
            }),
          };
          this.http.post(url, body, httpOptions /* { responseType: 'json' }*/).toPromise()
            .then((response: any) => {
              if (response.data) {
                resolve(response.data);
              } else if (response.err) {
                reject(response.err);
              } else if (response.error) {
                reject(response.error);
              } else {
                resolve(response);
              }
            }).catch((err) => {
              reject(err);
            });
        }).catch((err) => {
          reject(err);
        });  
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getSettingsLocal() {
    return new Promise((resolve, reject) => {
      reject('not implemented');
    });
  }

  // [ {item: item, value: value, ts: ts}, ... ]
  public setSettings(settingsArray: Setting[]) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.setSettingsLocal(settingsArray)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.setSettingsRemote(settingsArray)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  public setSettingsRemote(settingsArray: Setting[]) {
    console.log('*** setSettingsRemote', settingsArray);
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/setsettings`;
      const body = { settings: settingsArray };
      let token;
      this.deviceService.getuuid()
      .then((uuid: string) => {
        this.userService.getUser()
        .then((user: User) => {
          if (user) {
            token = user.token;
          } else {
            token = 'anonymous.' + uuid;
          }
          // body.debug = 'Y';
          const httpOptions = {
            headers: new HttpHeaders({
              'oauthToken': token,
              'oauthService': 'email'
            }),
          };
          console.log('sending to ', url, body, httpOptions);
          this.http.post(url, body, httpOptions /* { responseType: 'json' }*/).toPromise()
            .then((response: any) => {
              if (response.data) {
                resolve(response.data);
              } else if (response.err) {
                reject(response.err);
              } else if (response.error) {
                reject(response.error);
              } else {
                resolve(response);
              }
            }).catch((err) => {
              reject(err);
            });
        }).catch((err) => {
          reject(err);
        });  
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public setSettingsLocal(settingsArray: Setting[]) {
    return new Promise((resolve, reject) => {
      reject('not implemented');
    });
  }


  /*
  app.post('/setsettings', async (req, res) => {
  console.log('*** /setsettings');
  const settingsArray = req.body.settings;
  // [ {item: item, value: value, ts: ts}, ... ]
  */

  public getNote(vol: string, id: string) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getNoteLocal(vol, id)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getNoteRemote(vol, id)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  public getNoteRemote(vol: string, id: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/getnote`;
      const body = {
        vol: vol,
        id: id
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  public getNoteLocal(vol: string, id: string) {
    return new Promise((resolve, reject) => {
      const sql = `select txt from NOTES where note = '${id}'`;

      this.dbshuttleService.getSQL(vol, sql)
        .then((rows) => {
          resolve({
            text: rows.length > 0 ? rows[0].txt : '',
            vol: vol,
            id: id
          });
        }).catch((err) => {
          reject(err);
        });
    });
  }

  /*
  ===============================
  parameter object for getMarkers
  ===============================
  const hwm = req.body.hwm;
  const vols = req.body.vols;
  const book = req.body.book;
  const chapter = req.body.chapter;
  const verse = req.body.verse;

  const excludedeleted = req.body.excludedeleted;
  const orderby = req.body.orderby;
  const limit = req.body.limit;
  const offset = req.body.offset;
  const folder = req.body.folder;

  */
 // was hwm: number, vols: string, book: number, chapter: number, verse?: number
  public getMarkers(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getMarkersLocal(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        // console.log('*** db service calling getMarkersRemote', obj);
        this.getMarkersRemote(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            console.error('db service getMarkersRemote error', failure);
            reject(failure);
          });
      }
    });
  }

  getMarkersLocal(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      reject('not implemented yet');
    });
  }

  getMarkersRemote(obj): Promise<any> {
    // console.log('** getMarkersRemote passing body', obj);
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/getmarkers`;
      const body = obj;
      // body.debug = 'Y';
      const httpOptions = {
        headers: new HttpHeaders({
          'oauthToken': this.userService.user ? this.userService.user.token : 'anonymous',
          'oauthService': 'email'
        }),
      };
      this.http.post(url, body, httpOptions /* { responseType: 'json' }*/).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  public getData(vol: string, book: number, chapter: number, verse?: number, endverse?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getDataLocal(vol, book, chapter, verse, endverse)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getDataRemote(vol, book, chapter, verse, endverse)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            console.error('db service getDataRemote error', failure);
            reject(failure);
          });
      }
    });
  }

  getDataLocal(vol: string, b: number, c: number, v?: number, ev?: number): Promise<any> {
    let sql;
    return new Promise((resolve, reject) => {
      this.dbshuttleService.openVolLocal(vol)
        .then(() => {
          sql = `SELECT * from CONTENT where book = ${b} and chapter = ${c}`;
          if (v && !ev) {
            sql += ` and verse = ${v}`;
          } else if (v && ev) {
            sql += ` and verse >= ${v} and verse <= ${ev}`;
          }
          sql += ' order by verse';
          // console.log('getDataLocal', vol, sql);
          this.dbshuttleService.getSQL(vol, sql)
            .then((rows) => {
              resolve({
                text: rows,
                vol: vol,
                b: b,
                c: c,
                v: v,
                ev: ev
              });
            }).catch((sqlError) => {
              reject(sqlError);
            });
        }).catch((err) => {
          console.error('getDataLocal openVolLocal err', err);
          console.error('sql', sql);
          reject(err);
        });
    });
  }

  getDataRemote(vol: string, b: number, c: number, v?: number, ev?: number): Promise<any> {
    // console.log('** getDataRemote');
    return new Promise((resolve, reject) => {
      this.dbshuttleService.openVolRemote(vol)
        .then(() => {
          this.dbshuttleService.getDataRemote(vol, b, c, v, ev)
            .then((rows: any) => {
              // console.log('getDataRemote got rows:', rows);
              resolve({
                text: rows.text,
                vol: vol,
                b: b,
                c: c,
                v: v,
                ev: ev
              });
            }).catch((getDataRemoteError) => {
              reject(getDataRemoteError);
            });
        }).catch((err) => {
          reject(err);
        });
    });
  }

  nextChapter(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.nextChapterLocal(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.nextChapterRemote(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  nextChapterLocal(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbshuttleService.getSQL(vol, `SELECT book, chapter from CONTENT where book = ${book} and chapter > ${chapter} order by book, chapter, verse limit 1`)
        .then((lookupRows) => {
          if (lookupRows.length > 0) {
            this.dbshuttleService.getSQL(vol, `SELECT * from CONTENT where book = ${book} and chapter = ${lookupRows[0].chapter} order by book, chapter, verse`)
              .then((rows) => {
                resolve({
                  text: rows,
                  vol: vol,
                  b: parseInt(lookupRows[0].book, 10),
                  c: parseInt(lookupRows[0].chapter, 10)
                });

              }).catch((err) => {
                reject(err);
              });

          } else {

            this.dbshuttleService.getSQL(vol,
              `SELECT book, chapter from CONTENT where book > ${book} order by book, chapter, verse limit 1`)
              .then((lookupRows2) => {
                if (lookupRows2.length > 0) {

                  this.dbshuttleService.getSQL(vol, `SELECT * from CONTENT where book = ${lookupRows2[0].book} and chapter = ${lookupRows2[0].chapter} order by book, chapter, verse`)
                    .then((rows) => {
                      resolve({
                        text: rows,
                        vol: vol,
                        b: parseInt(lookupRows2[0].book, 10),
                        c: parseInt(lookupRows2[0].chapter, 10)
                      });
                    }).catch((err) => {
                      reject(err);
                    });
                } else {
                  reject({ err: 'END' });
                }
              }).catch((err) => {
                reject(err);
              });
          }

        }).catch((err) => {
          reject(err);
        });

    });
  }

  nextChapterRemote(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/nextchapter`;
      const body = {
        vol: vol,
        b: book,
        c: chapter
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }

  prevChapter(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.prevChapterLocal(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.prevChapterRemote(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  prevChapterLocal(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {

      this.dbshuttleService.getSQL(vol, `SELECT book, chapter from CONTENT where book = ${book} and chapter < ${chapter} order by book desc, chapter desc, verse desc limit 1`)
        .then((lookupRows) => {
          if (lookupRows.length > 0) {
            this.dbshuttleService.getSQL(vol, `SELECT * from CONTENT where book = ${book} and chapter = ${lookupRows[0].chapter} order by book, chapter, verse`)
              .then((rows) => {
                resolve({
                  text: rows,
                  vol: vol,
                  b: parseInt(lookupRows[0].book, 10),
                  c: parseInt(lookupRows[0].chapter, 10)
                });

              }).catch((err) => {
                reject(err);
              });

          } else {

            this.dbshuttleService.getSQL(vol, `SELECT book, chapter from CONTENT where book < ${book} order by book desc, chapter desc, verse desc limit 1`)
              .then((lookupRows2) => {
                if (lookupRows2.length > 0) {
                  this.dbshuttleService.getSQL(vol, `SELECT * from CONTENT where book = ${lookupRows2[0].book} and chapter = ${lookupRows2[0].chapter} order by book, chapter, verse`)
                    .then((rows) => {
                      resolve({
                        text: rows,
                        vol: vol,
                        b: parseInt(lookupRows2[0].book, 10),
                        c: parseInt(lookupRows2[0].chapter, 10)
                      });
                    }).catch((err) => {
                      reject(err);
                    });
                } else {
                  reject({ err: 'END' });
                }
              }).catch((err) => {
                reject(err);
              });
          }

        }).catch((err) => {
          reject(err);
        });

    });
  }

  prevChapterRemote(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/prevchapter`;
      const body = {
        vol: vol,
        b: book,
        c: chapter
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }

  listChapters(vol: string, book: number): Promise<any> {
    // console.log('** listChapters', vol, book, typeof book);
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.listChaptersLocal(vol, book)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.listChaptersRemote(vol, book)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  listChaptersRemote(vol: string, book: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/listchapters`;
      const body = {
        vol: vol,
        book: book
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }

  listChaptersLocal(vol: string, book: number): Promise<any> {
    // console.log('listChaptersLocal', vol, book, typeof book);
    // console.log(`select distinct chapter from content where book = ${book} order by chapter`);
    return new Promise((resolve, reject) => {
      this.dbshuttleService.getSQL(vol, `select distinct chapter from content where book = ${book} order by chapter`)
        .then((rows) => {
          // console.log('rows', rows);
          resolve({
            rows: rows,
            vol: vol,
            book: book
          });
        }).catch((sqlError) => {
          reject(sqlError);
        });

    });
  }

  listVerses(vol: string, book: number, chapter: number): Promise<any> {
    // console.log('** listVerses', vol, book, typeof book, chapter, typeof chapter);
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.listVersesLocal(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.listVersesRemote(vol, book, chapter)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  listVersesRemote(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/listverses`;
      const body = {
        vol: vol,
        book: book,
        chapter: chapter
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }

  listVersesLocal(vol: string, book: number, chapter: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log('listVersesLocal');
      // console.log(`select distinct verse from content where book = ${book} and chapter = ${chapter} order by chapter, verse`);
      this.dbshuttleService.getSQL(vol, `select distinct verse from content where book = ${book} and chapter = ${chapter} order by chapter, verse`)
        .then((rows) => {
          // console.log('rows', rows);
          resolve({
            rows: rows,
            vol: vol,
            book: book,
            chapter: chapter
          });
        }).catch((sqlError) => {
          reject(sqlError);
        });

    });
  }

  getHistory(userID: string, limit: number, offset: number) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getHistoryLocal(userID, limit, offset)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getHistoryRemote(userID, limit, offset)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  getHistoryLocal(userID: string, limit: number, offset: number) {
    return new Promise((resolve, reject) => {
      this.dbshuttleService.getSQL('storage',
        `select * from history where user = '${userID}' order by ts desc limit ${limit} offset ${offset}`)
        .then((result) => {
          resolve(result);
        }).catch((err) => {
          console.error('getHistoryLocal error', err);
          reject(err);
        });
    });
  }

  getHistoryRemote(userID: string, limit: number, offset: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/gethistory`;
      const body = {
        userID: userID,
        limit: limit,
        offset: offset
      };
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.result) {
            resolve(response.result);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          console.error('**** getHistoryRemote error', err);
          reject(err);
        });

    });
  }

  public deleteAllHistory(userID: string) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.deleteAllHistoryLocal(userID)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.deleteAllHistoryRemote(userID)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  deleteAllHistoryLocal(userID) {
    return new Promise((resolve, reject) => {
      this.dbshuttleService.sqlBatch('storage', [
        `delete from history where user = '${userID}'`
      ]).then((result) => {
        resolve(result);
      }).catch((err) => {
        console.error('error saving history item', err);
        reject(err);
      });
    });
  }

  deleteAllHistoryRemote(userID) {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/deleteallhistory`;
      const body = {
        userID: userID
      };
      console.log('deleteAllHistoryRemote', url, body);
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  saveHistory(obj: History) {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.saveHistoryLocal(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.saveHistoryRemote(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  saveHistoryLocal(obj: History) {
    return new Promise((resolve, reject) => {
      this.dbshuttleService.sqlBatch('storage', [
        `replace into history (user,vol,volumeClass,book,chapter,verse,title,abstract,ts) values ('${obj.user}','${obj.vol}','${obj.volumeClass}','${obj.book}','${obj.chapter}','${obj.verse}','${obj.title.replace(/'/g, '\'\'')}','${obj.abstract.replace(/'/g, '\'\'')}',${(+new Date())})`
      ]).then((result) => {
        resolve(result);
      }).catch((err) => {
        console.error('error saving history item', err);
        reject(err);
      });
    });
  }

  saveHistoryRemote(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.dbshuttleService.APIHOST}/savehistory`;
      const body = {
        rows: [obj]
      };
      // console.log('saveHistoryRemote', url, body);
      this.http.post(url, body, { responseType: 'json' }).toPromise()
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          } else if (response.err) {
            reject(response.err);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response);
          }
        }).catch((err) => {
          reject(err);
        });

    });
  }


  createTable() {
    /*
    this.databaseObj.executeSql('CREATE TABLE IF NOT EXISTS ' + this.table_name + ' (pid INTEGER PRIMARY KEY, Name varchar(255))', [])
      .then(() => {
        alert('Table Created!');
      })
      .catch(e => {
        alert("error " + JSON.stringify(e))
      });
      */
  }

  insertRow() {
    /*
    this.databaseObj.executeSql('INSERT INTO ' + this.table_name + ' (Name) VALUES ("' + this.name_model + '")', [])
      .then(() => {
        alert('Row Inserted!');
        this.getRows();
      })
      .catch(e => {
        alert("error " + JSON.stringify(e))
      });
      */
  }


}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VolinfoService {
  public volinfo: any = {};
  constructor() { }
  public setBooks(vol, books) {
    if (!this.volinfo[vol]) {
      this.volinfo[vol] = {};
    }
    this.volinfo[vol].books = [];
    // console.log('** books.length', books.length);
    for (const book of books) {
      // this.volinfo[vol].books[parseInt(book.book, 10)] = book;
      this.volinfo[vol].books[book.book] = book;
    }
  }
  public setVolInfo(vol, volinfo) {
    if (!this.volinfo[vol]) {
      this.volinfo[vol] = {};
    }
    this.volinfo[vol].volinfo = volinfo;
  }
  /*
  public getVolInfo(vol) {
    return new Promise((resolve, reject) => {

    });
  }
  */
}

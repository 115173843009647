import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { LibraryService } from '../../services/library.service';
import { Volume } from '../../interfaces/volume';
import { LibraryDetailPage } from '../library-detail/library-detail.page';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-study-bar-settings',
  templateUrl: './study-bar-settings.page.html',
  styleUrls: ['./study-bar-settings.page.scss'],
})
export class StudyBarSettingsPage implements OnInit {

  // public favoritesVolumes: Volume[] = [];
  public favorites: Volume[] = [];
  public nonFavorites: Volume[] = [];
  public volumeClass: string;
  public pageTitle = '';
  public classTitle = '';
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private libraryService: LibraryService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    window.name = 'Study Bar Settings';
    console.log('****************************');
    console.log('*** study-bar-settings');
    console.log('*** navParams: ', this.navParams.data);
    console.log('****************************');
    if (this.navParams.data && this.navParams.data.volumeClass) {
      this.volumeClass = this.navParams.data.volumeClass;
    }
    this.classTitle = (this.volumeClass === 'BIBLE' ? 'Bibles' : 'Commentaries');
    this.loadData();
  }
  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { key: 'value' }
    );
  }
  loadData() {
    this.favorites = this.libraryService.getFavoriteVolumes(this.volumeClass);
    this.nonFavorites = this.libraryService.getNonFavoriteVolumes(this.volumeClass);
  }

  doReorder($event: any) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', $event.detail.from, 'to', $event.detail.to);
    const from = $event.detail.from;
    const to = $event.detail.to;
    /*
    group.splice(from, 1);
    console.log('group', group);
    group.splice(to, 0, element);
    console.log('group', group);
    */
    this.move(from, to, this.favorites);
    // console.log(this.favorites);
    // console.log(this.bibles);
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    $event.detail.complete(this.favorites);
    this.libraryService.favorites[this.volumeClass] = this.favorites.map((vol) => vol.tbl);
    this.libraryService.saveFavorites();
  }
  move(from, to, ...a) {
    return (from === to
    ? a
    : (a.splice(to, 0, ...a.splice(from, 1)), a));
  }

  getInfo($event: Event, volume: Volume) {
    $event.stopPropagation();
    this.libraryDetail(volume);
  }
  async libraryDetail(volume: Volume): Promise<any> {
    const modal = await this.modalController.create({
      component: LibraryDetailPage,
      componentProps: {
        volume: volume
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      if (dataReturned && dataReturned.data) {
        // console.log('LibraryDetailPage dataReturned', dataReturned);
        const data = dataReturned.data;
       //  console.log('LibraryDetailPage data', data);
      }
    });
    return await modal.present();
  }

  addFavorite($event, vol, index) {
    // console.log('** addFavorite', vol);
    const heartImage: any = document.getElementById('heart-' + vol.tbl);
    heartImage.name = 'heart'; // make it a solid heart

    const _this = this;
    let delay = 60;
    function animateUp() {
      delay = delay - 10;
      if (delay < 0) { delay = 0; }
      if (index < 0) {
        _this.libraryService.addToFavorites(vol, false);
        _this.loadData();
        _this.changeDetectorRef.detectChanges();
        return;
      } else {
        const newIndex = index - 1;
        const removedItems = _this.nonFavorites.splice(index, 1);
        _this.nonFavorites.splice(newIndex, 0, removedItems[0]);
        _this.changeDetectorRef.detectChanges();
        index--;
        setTimeout(() => {
          animateUp();
        }, delay);
      }
    }
    animateUp();
  }

  removeFavorite($event, vol, index) {
    const heartImage: any = document.getElementById('heart-' + vol.tbl);
    heartImage.name = 'heart-outline'; // make it a solid heart

    // this.libraryService.removeFromFavorites(vol);
    // this.loadData();
    const _this = this;
    let delay = 60;
    function animateDown() {
      delay = delay - 10;
      if (delay < 0) { delay = 0; }
      if (index > _this.favorites.length - 1) {
        _this.libraryService.removeFromFavorites(vol);
        _this.loadData();
        _this.changeDetectorRef.detectChanges();
        return;
      } else {
        const newIndex = index + 1;
        const removedItems = _this.favorites.splice(index, 1);
        _this.favorites.splice(newIndex, 0, removedItems[0]);
        _this.changeDetectorRef.detectChanges();
        index++;
        setTimeout(() => {
          animateDown();
        }, delay);
      }
    }
    animateDown();

  }
  



}

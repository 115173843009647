import { Injectable } from '@angular/core';
import { DbLocalSettingsService } from './db-local-settings.service';
import { DisplaySettings } from '../interfaces/display-settings';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DisplaySettingsService {
  public displaySettings: DisplaySettings;

  /*
  public displaySettings = new BehaviorSubject<DisplaySettings>(
    {
      '--font-size': '15pt',
      '--main-background-color': 'white'
    }
  );
  */

  constructor(
    private dbLocalSettingsService: DbLocalSettingsService,
    private userService: UserService
  ) {
    this.setDefaultSettings();
    this.userService.userChanged.subscribe((user) => {
      this.loadSavedDisplaySettings();
    });
  }

  setDefaultSettings() {
    this.displaySettings = {
      '--font-size': '15pt',
      '--display-margin-top': '20pt',
      '--display-margin-left': '20pt',
      '--display-margin-right': '20pt',
      '--display-margin-bottom': '20pt',
      '--verse-menu-button-font-size': '15pt'
    };
  }
  public loadSavedDisplaySettings() {
    this.dbLocalSettingsService.getLocalSetting('displaySettings')
    .then((displaySettings: DisplaySettings) => {
      if (displaySettings) {
        // tslint:disable-next-line: forin
        for (const key in displaySettings) {
          this.displaySettings[key] = displaySettings[key];
        }
      } else {
        this.setDefaultSettings();
      }
      this.update();
    }).catch((err) => {
      console.log('error getting displaySettings from local storage', err);
    });
  }

  public update(displaySettings?: DisplaySettings, save: boolean = false) {
    if (displaySettings) {
      // first apply default settings that were not sent to us
      // tslint:disable-next-line: forin
      for (const key in this.displaySettings) {
        if (!displaySettings[key]) {
          window.document.body.style.setProperty(key, this.displaySettings[key]);
        }
      }
      // apply any settings sent to us here...
      // tslint:disable-next-line: forin
      for (const key in displaySettings) {
        this.displaySettings[key] = displaySettings[key];
        window.document.body.style.setProperty(key, displaySettings[key]);
      }
    } else {
      // just apply all settings...
      // tslint:disable-next-line: forin
      for (const key in this.displaySettings) {
        // console.log(`*** ${key} = ${displaySettings[key]}`);
        window.document.body.style.setProperty(key, this.displaySettings[key]);
      }
    }
    if (save) {
      // console.log('****** saving displaySettings to localSettings');
      this.dbLocalSettingsService.setLocalSetting('displaySettings', this.displaySettings)
      .then((result) => {
        // console.log('display settings saved', result);
      })
      .catch((err) => {
        console.error('Error in saveSettings', err);
      });
    }
  }

}

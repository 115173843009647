import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LibraryPageRoutingModule } from './library-routing.module';

import { LibraryPage } from './library.page';
import { PopoverPage } from '../library-popover/library-popover';

import { LibraryDetailPage } from '../library-detail/library-detail.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LibraryPageRoutingModule
  ],
  declarations: [LibraryPage, PopoverPage, LibraryDetailPage],
  entryComponents: [PopoverPage, LibraryDetailPage],
  bootstrap: [LibraryPage],
})
export class LibraryPageModule {}

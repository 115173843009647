import { Injectable } from '@angular/core';
import { DbshuttleService } from './dbshuttle.service';
import { UserService } from '../services/user.service';
import { User } from '../interfaces/user';
import { Storage } from '@ionic/storage';
import { DbService } from './db.service';
import { Setting } from '../interfaces/setting';

@Injectable({
  providedIn: 'root'
})
export class DbLocalSettingsService {

  constructor(
    private dbshuttleService: DbshuttleService,
    private userService: UserService,
    private storage: Storage,
    private db: DbService
  ) { 
    setTimeout(() => {
      this.listLocalSetings()
      .then((result: Setting[]) => {
        console.log('**** all settings', result);
        this.db.setSettings(result)
        .then((success) => {
          console.log('setSettings success', success);
        }).catch((failure) => {
          console.error('setSettings failed', failure);
        });
      }).catch((err) => {
        console.log('*** settings error', err);
      });
    }, 4000);
    this.db.getSettings()
    .then((settings) => {
      console.log('*** settings', settings);
    }).catch((err) => {
      console.error('db-local-settings getSetting error', err);
    });
  }


  getLocalSetting(setting: string) {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
      .then((user: User) => {
        let userID;
        if (user === null || typeof user.id !== 'string') {
          userID = 'anonymous';
        } else {
          userID = user.id;
        }
        this.storage.get(`${userID}.${setting}`)
        .then((result) => {
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  setLocalSetting(setting: string, value: any, type: string = 'string') {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
      .then((user: User) => {
        let userID;
        if (user === null || typeof user.id !== 'string') {
          userID = 'anonymous';
        } else {
          userID = user.id;
        }
        this.storage.set(`${userID}.${setting}`, value)
        .then((result) => {
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public listLocalSetings() {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
      .then((user: any) => {
        const allSettings = [];
        if (user) {
          this.storage.forEach((value, key) => {
            if (key.startsWith(user.id)) {
              key = key.replace(user.id + '.', '');
              // console.log('SETTING => ' + key, value);
              allSettings.push({ item: key, value: value, ts: +new Date() });
              // console.log('allSettings.length', allSettings.length);
            }
          }).then(() => {
            // 7da4c18d-4455-f5c7-d14c-74ce013fa3cc
            const allSettingsStr = JSON.stringify(allSettings).replace(/7da4c18d-4455-f5c7-d14c-74ce013fa3cc\./g, '');
            // console.log('all settings: ', allSettingsStr);
            // console.log('total size of all settings: ', allSettingsStr.length);
            resolve(allSettings);
          });
        } else {
          reject('no user');
        }
      }).catch((err) => {
        reject('no user');
      });
    });
  }

  escapeQ(token: string) {
    return (token.replace(/'/g, '\'\''));
  }
  getLocalSettingOLD(setting: string) {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
      .then((user: User) => {
        let userID;
        if (user === null || typeof user.id !== 'string') {
          userID = 'anonymous';
        } else {
          userID = user.id;
        }
        this.dbshuttleService.getSQL('storage',
        `select value,type from localsettings where setting = '${this.escapeQ(setting)}' and user = '${this.escapeQ(userID)}'`)
        .then(function(rows){
          let result;
          try {
            if (rows && rows.length > 0) {
              switch (rows[0].type) {
                case 'number':
                  if (rows[0].value.indexOf('.') > -1) {
                    result = parseFloat(rows[0].value);
                  } else {
                    result = parseInt(rows[0].value, 10);
                  }
                  break;
                case 'integer':
                case 'int':
                  result = parseInt(rows[0].value, 10);
                  break;
                case 'float':
                  result = parseFloat(rows[0].value);
                  break;
                case 'boolean':
                  if (rows[0].value === 'true') {
                    result = true;
                  } else {
                    result = false;
                  }
                  break;
                case 'null':
                  result = null;
                  break;
                case 'undefined':
                  result = undefined;
                  break;
                case 'object':
                  result = JSON.parse(rows[0].value);
                  break;
                default: // string
                  result = rows[0].value;
                  break;
              }
            } else {
              result = null;
            }
          } catch (err) {
            console.error('getLocalSetting db error:', err);
            result = null;
          }
          resolve(result);
        }).catch(function(err){
          if (err.code === 5 && err.message === 'no such table: localsettings') {
            console.error('**********************************************************************')
            console.error('*** localsettings table does not yet exist, returning a null entry ***');
            console.error('**********************************************************************')
            resolve(null);
          } else {
            reject(err);
          }
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  setLocalSettingOLD(setting, value, type: string = 'string') {
    return new Promise((resolve, reject) => {
      let userID;
      this.userService.getUser()
      .then((user: User) => {
        if (user === null || typeof user.id !== 'string') {
          userID = 'anonymous';
        } else {
          userID = user.id;
        }
        if (value === null) {
          type = 'null';
        }
        switch (type) {
          case 'number':
          case 'integer':
          case 'int':
          case 'float':
            value = value.toString();
            break;
          case 'boolean':
            if (value) {
              value = 'true';
            } else {
              value = 'false';
            }
            break;
          case 'null':
            value = 'null';
            break;
          case 'undefined':
            value = 'undefined';
            break;
          case 'object':
            value = JSON.stringify(value);
            break;
          default: // string
            break;
        }
        this.dbshuttleService.getSQL('storage',
        `replace into localsettings (setting,value,type,user,ts) values ('${this.escapeQ(setting)}','${this.escapeQ(value)}','${this.escapeQ(type)}','${this.escapeQ(userID)}',${+new Date()})`)
        .then(function(result){
          resolve(result);
        }).catch(function(err){
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ActionBarQuickBookmarkPageRoutingModule } from './action-bar-quick-bookmark-routing.module';

import { ActionBarQuickBookmarkPage } from './action-bar-quick-bookmark.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ActionBarQuickBookmarkPageRoutingModule
  ],
  declarations: [ActionBarQuickBookmarkPage]
})
export class ActionBarQuickBookmarkPageModule {}

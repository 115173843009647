import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MarkersService } from '../../services/markers.service';

@Component({
  selector: 'app-action-bar-quick-highlight',
  templateUrl: './action-bar-quick-highlight.page.html',
  styleUrls: ['./action-bar-quick-highlight.page.scss'],
})
export class ActionBarQuickHighlightPage implements OnInit {
  public dark = false;
  // public v: number;
  public styleChoices = [
    'yellow',
    'green',
    'pink',
    'blue',
    'orange',
    'cyan',
    'tan',
    'purple',
    'gray',
    'bold',
    'underline',
    'double',
    'dotted',
    'dashed',
    'overline',
    'reverse'
  ];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public m: MarkersService
  ) { }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.dark) {
      this.dark = true;
    }
  }
  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { settings: this.m.defaultMarkerSettings }
    );
  }
  selectStyle(style) {
    this.m.defaultMarkerSettings.style = style;
    this.m.saveDefaultMarkerSettings();
  }

  placeHighlight() {
    this.closeModal(false); // do not cancel
  }

}

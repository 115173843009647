import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

import { SQLite } from '@ionic-native/sqlite/ngx';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { BcvPageModule } from './pages/bcv/bcv.module';
import { LibraryPageModule } from './pages/library/library.module';
import { DisplaySettingsPageModule } from './pages/display-settings/display-settings.module';
import { StudyBarSettingsPageModule } from './pages/study-bar-settings/study-bar-settings.module';
import { ActionBarSettingsPageModule } from './pages/action-bar-settings/action-bar-settings.module';
import { ActionBarQuickBookmarkPageModule } from './pages/action-bar-quick-bookmark/action-bar-quick-bookmark.module';
import { ActionBarQuickHighlightPageModule } from './pages/action-bar-quick-highlight/action-bar-quick-highlight.module';
import { TutorialActionBarPageModule } from './pages/tutorials/tutorial-action-bar/tutorial-action-bar.module';
import { SelectItemFromListPageModule } from './pages/select-item-from-list/select-item-from-list.module';
import { SearchVolumesPageModule } from './pages/search-volumes/search-volumes.module';
import { SqliteDbCopy } from '@ionic-native/sqlite-db-copy/ngx';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    BcvPageModule,
    LibraryPageModule,
    StudyBarSettingsPageModule,
    ActionBarSettingsPageModule,
    ActionBarQuickBookmarkPageModule,
    ActionBarQuickHighlightPageModule,
    TutorialActionBarPageModule,
    DisplaySettingsPageModule,
    SelectItemFromListPageModule,
    SearchVolumesPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, SafeHtmlPipe],
  providers: [InAppBrowser, SplashScreen, StatusBar, SQLite, SqliteDbCopy],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DbLocalSettingsService } from '../../services/db-local-settings.service';

@Component({
  selector: 'app-select-item-from-list',
  templateUrl: './select-item-from-list.page.html',
  styleUrls: ['./select-item-from-list.page.scss'],
})
export class SelectItemFromListPage implements OnInit {
  public items = <any>[];
  public title = 'Select:';
  public id; // unique identifier for this screen in order to save / restore custom settings
  public mode = 'list'; // grid (chip)
  public disableGrid = false;
  public extraHTML = '';
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private dbLocalSettingsService: DbLocalSettingsService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    console.log('** ionViewWillEnter');
    console.log('this.navParams', this.navParams);
    console.log('this.navParams.data', this.navParams.data);
    if (this.navParams.data.id) {
      this.id = this.navParams.data.id;
    }
    if (this.navParams.data.mode) {
      this.mode = this.navParams.data.mode;
    }
    if (this.navParams.data.disableGrid) {
      this.disableGrid = true;
    }
    if (this.navParams.data.extraHTML) {
      this.extraHTML = this.navParams.data.extraHTML;
    }
    // console.log('this.id', this.id);
    if (this.id) {
      console.log('getting mode from storage');
      this.dbLocalSettingsService.getLocalSetting('select-item-from-list.mode.' + this.id)
      .then((setting: any) => {
        if (setting) {
          console.log('got setting', setting);
          this.mode = setting;
        }
      }).catch((err) => {
        console.error('error getting setting from storage', err);
      });
    }
    this.items = this.navParams.data.items;
    // console.log(document.getElementById('extraHTML'));
  }

  toggleMode() {
    if (this.mode === 'list') {
      this.mode = 'grid';
    } else {
      this.mode = 'list';
    }
    this.dbLocalSettingsService.setLocalSetting('select-item-from-list.mode.' + this.id, this.mode);
  }

  async closeModal(item) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      { selectedItem: item }
    );
  }

  selectItem(item) {
    this.closeModal(item);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { User } from '../interfaces/user';
import { Platform } from '@ionic/angular';
import { UtilsService } from './utils.service';
import { Marker } from '../interfaces/marker';
import { DbLocalSettingsService } from './db-local-settings.service';
// importmarkersfrommx
@Injectable({
  providedIn: 'root'
})
export class MarkersService {
  public APIHOST = 'https://mxiapi.mantisbible.com';
  public isCordova = false;
  public defaultMarkerSettings = {
    icon: 'bookmark',
    iconcolor: 'black',
    style: 'yellow'
  };

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private platform: Platform,
    private utilsService: UtilsService,
    private dbLocalSettingsService: DbLocalSettingsService
  ) {
    this.isCordova = this.platform.is('cordova');
    this.loadDefaultMarkerSettings()
    .then((defaultMarkerSettings: any) => {
      if (defaultMarkerSettings) {
        this.defaultMarkerSettings = defaultMarkerSettings;
      }
    }).catch((err) => {
      console.error('error loading default marker settings', err);
    });
  }

  public saveMarker(marker: Marker): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.saveMarkerLocal(marker)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.saveMarkerRemote(marker)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }
  saveMarkerLocal(marker: Marker) {
    return new Promise((resolve, reject) => {
      reject('not implemented');
    });
  }
  saveMarkerRemote(marker: Marker) {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then((user: User) => {
          if (user) {
            console.log('user is', user);
            const httpOptions = {
              headers: new HttpHeaders({
                'oauthToken': user.token,
                'oauthService': 'email'
              })
            };
            const url = `${this.APIHOST}/savemarkers`;
            // convert marker to multi-dimensional array
            const arr = [];
            // tslint:disable-next-line: forin
            for (const attr in marker) {
              arr.push(marker[attr]);
            }
            const body = {
              data: [arr]
            };
            console.log('sending request to ', url, httpOptions, body);
            this.http.post(url, body, httpOptions).toPromise()
              .then((response: any) => {
                if (response.data) {
                  console.log('*** savemarkers', response.data);
                  if (response.data.status === 0) {
                    resolve(response.data);
                  } else {
                    reject(response.data.err || response.data.error || response.data);
                  }
                } else if (response.err) {
                  console.error('*** savemarkers error', response.err);
                  reject(response.err);
                } else if (response.error) {
                  console.error('*** savemarkers error', response.error);
                  reject(response.error);
                } else {
                  console.error('*** savemarkers unknown response / error');
                  reject(response);
                }
              }).catch((err) => {
                console.log('savemarkers got err:', err);
                reject(err);
              });

          } else {
            reject('no user');
          }
        }).catch((err) => {
          reject(err);
        });


    });
  }
  
  /*
  public getMarkers(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isCordova) {
        this.getMarkersLocal(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      } else {
        this.getMarkersRemote(obj)
          .then((success) => {
            resolve(success);
          }).catch((failure) => {
            reject(failure);
          });
      }
    });
  }

  getMarkersLocal(obj) {
    return new Promise((resolve, reject) => {
      reject('getMarkersLocal is not yet implemented');
    });
  }

  getMarkersRemote(obj) {
    // console.log('*** getBookshelfRemote');

    return new Promise((resolve, reject) => {

      this.userService.getUser()
        .then((user: User) => {
          if (user) {
            const httpOptions = {
              headers: new HttpHeaders({
                'oauthToken': user.token,
                'oauthService': 'email',
              })
            };
            const url = `${this.APIHOST}/getmarkers`;
            const body = obj;
            this.http.post(url, body, httpOptions).toPromise()
              .then((response: any) => {
                if (response.data) {
                  console.log('*** getmarkers', response.data);
                  resolve(response.data);
                } else if (response.err) {
                  console.error('*** getmarkers error', response.err);
                  reject(response.err);
                } else if (response.error) {
                  console.error('*** getmarkers error', response.error);
                  reject(response.error);
                } else {
                  console.error('*** getmarkers unknown response / error');
                  reject(response);
                }
              }).catch((err) => {
                console.log('getmarkers got err:', err);
                reject(err);
              });

          } else {
            reject('no user');
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }
  */
  /*
+    id: "73d1e0ba-5494-0f59-121c-ad0989ffa59b"
+    user: "7da4c18d-4455-f5c7-d14c-74ce013fa3cc"
+    vol: "ANY"
+    title: "John 6:10"
+    icon: "ion-ios-musical-notes"
+    iconcolor: "black"
+    book: 43
+    chapter: 6
+    verse: 10
+    word: 1
+    endBook: 43
+    endChapter: 6
+    endVerse: 10
+    endWord: 999
+    style: ""
+    note: "note text here"
+    tags: "note"
+    ts: 1511774615753
+    deleted: ""
+    displayRef: "John 6:10"
+    folder: ""
+    sortKey: 32
  */

  public createMarker(obj: any) {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
      .then((user: User) => {
        if (user && user.id) {
          const marker: Marker = {
            id: this.utilsService.guid(),
            user: user.id,
            vol: 'ANY',
            title: '',
            icon: '',
            iconcolor: '',
            book: 0,
            chapter: 0,
            verse: 0,
            word: 0,
            endBook: 0,
            endChapter: 0,
            endVerse: 0,
            endWord: 0,
            style: '',
            note: '',
            tags: '',
            ts: +new Date(),
            deleted: '',
            displayRef: '',
            folder: '',
            sortKey: 0
          };
          // tslint:disable-next-line: forin
          for (const attr in obj) {
            marker[attr] = obj[attr];
          }
          resolve(marker);
        } else {
          reject('no user');
        }
      }).catch((err) => {
        console.error('createMarker could not get user', err);
        reject(err);
      });
    });
  }

  public saveDefaultMarkerSettings() {
    return new Promise((resolve, reject) => {
      this.dbLocalSettingsService.setLocalSetting('defaultMarkerSettings', this.defaultMarkerSettings)
      .then((result) => {
        resolve(result);
      }).catch((err) => {
        console.error('error saving defaultMarkerSettings to local storage', err);
        reject(err);
      });
    });
  }
  public loadDefaultMarkerSettings() {
    // console.log('** loadDefaultMarkerSettings');
    return new Promise((resolve, reject) => {
      this.dbLocalSettingsService.getLocalSetting('defaultMarkerSettings')
      .then((defaultMarkerSettings: any) => {
        // console.log('we got', defaultMarkerSettings);
        if (defaultMarkerSettings) {
          resolve(defaultMarkerSettings);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        console.error('error getting defaultMarkerSettings from local storage', err);
        reject(err);
      });
    });
  }

  public findGlobalMarker(markers: Marker[], v: number) {
    let marker: Marker = null;
    const markerArr = markers.filter((m: Marker) => {
      return m.verse === v &&
              m.word === 1 &&
              m.endWord === 999 &&
              m.vol === 'ANY' &&
              m.deleted === '';
    });
    if (markerArr.length > 0) {
      marker = markerArr[0];
    }
    return marker;
  }

}


import { Component, Input } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  template: `
    <ion-list>
      <ion-item lines="full" (click)="close('sortByName')">
      <ion-icon name="funnel-outline" slot="start"></ion-icon>
        <ion-label>Sort By Name</ion-label>
      </ion-item>
      <ion-item lines="full" (click)="close('sortByTbl')">
      <ion-icon name="funnel-outline" slot="start"></ion-icon>
        <ion-label>Sort By ID</ion-label>
      </ion-item>
      <ion-item lines="full" (click)="close('clearRecentVolumes')">
      <ion-icon name="close-circle-outline" slot="start"></ion-icon>
        <ion-label>Clear Recent</ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-toggle slot="start" color="primary" [(ngModel)]="librarySettings.wrapMode"></ion-toggle>
        <ion-label>Wrap Names</ion-label>
      </ion-item>
    </ion-list>
  `
})
export class PopoverPage {
  public wrapMode = true;
  @Input() librarySettings: any;
  constructor(public popoverCtrl: PopoverController /*, public navParams: NavParams */) {
    // console.log('this.navParams.get', this.navParams.get('librarySettings'));
    // this.librarySettings = this.navParams.get('librarySettings');
  }

  support() {
    // this.app.getRootNavs()[0].push('/support');
    this.popoverCtrl.dismiss();
  }

  close(message: string) {
    this.popoverCtrl.dismiss({message: message });
  }

}

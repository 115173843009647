import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SearchVolumesPageRoutingModule } from './search-volumes-routing.module';

import { SearchVolumesPage } from './search-volumes.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SearchVolumesPageRoutingModule
  ],
  declarations: [SearchVolumesPage]
})
export class SearchVolumesPageModule {}

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { DownloadState } from '../interfaces/downloadstate';
import { DbshuttleService } from '../services/dbshuttle.service';

declare var window: any;
declare var FileTransfer: any;

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private state: DownloadState = {
    queue: [],
    downloading: false,
    currentFile: '',
    loaded: 0,
    total: 0
  };
  public downloadState = new BehaviorSubject<DownloadState>({
    queue: [],
    downloading: false,
    currentFile: '',
    loaded: 0,
    total: 0
  });
  public bookshelfChanged = new BehaviorSubject<boolean>(false);
  public downloadsComplete = new BehaviorSubject<boolean>(false);
  private path: string;
  private isCordova = false;
  private ft;
  constructor(
    private platform: Platform,
    private dbshuttleService: DbshuttleService
  ) {
    this.isCordova = this.platform.is('cordova');

    if (this.isCordova) {
      this.path = window.cordova.file.applicationStorageDirectory;
      if (this.platform.is('ios')) {
        console.log('platform is ios');
        this.path += 'Library/LocalDatabase/';
      } else if (this.platform.is('android')) {
        console.log('platform is android');
        this.path += 'databases/';
      } else {
        console.error('listIntalledFiles error, platform is not ios or android');
      }
      this.ft = new FileTransfer();
    }
  }

  public addFile(file: string) {
    if (this.state.queue.indexOf(file) === -1) {
      this.state.queue.push(file);
      this.downloadState.next(this.state);
      return true;
    } else {
      return false;
    }
  }

  public removeFile(file: string) {
    const index = this.state.queue.indexOf(file);
    if (index > -1) {
      this.state.queue.splice(index, 1);
      this.downloadState.next(this.state);
      return true;
    } else {
      return false;
    }
  }

  public download(file: string) {
    console.log('** download ', file);
    // this.state.
    this.state.currentFile = file;
    this.downloadState.next(this.state);
    const _this = this;

    document.addEventListener('deviceready', function () {
      console.log('*** deviceready');

      let ts = 0;

      console.log('*** download file: ' + file);
      const url = 'https://content.mantisbible.com/mxi/' + file + '.db4';

      const successHandler = (obj) => {
        console.log('** successHandler', obj);
        _this.dbshuttleService.sqlBatch('bookshelf', [`update volume set installed = 'Y' where tbl = '${file}'`])
          .then((success) => {
            _this.state.currentFile = '';
            _this.state.loaded = 0;
            _this.state.total = 0;
            _this.downloadState.next(_this.state);
            _this.state.queue.shift(); // last file was updated, remove it from the queue
            _this.bookshelfChanged.next(true);
            _this.processDownloadQueue();
          }).catch((err) => {
            console.error('Error updating bookself to set ' + file + ' as installed', err);
          });
      };
      const errorHandler = (obj) => {
        console.error('** errorHandler', obj);
      };

      _this.ft.onprogress = (obj) => {
        // debounce 1 per second
        if ((+new Date() - ts) > 500) {
          console.log('progress', obj);
          _this.state.loaded = obj.loaded;
          _this.state.total = obj.total;
          _this.downloadState.next(_this.state);
          ts = +new Date();
        }
        /*
          "progress" {
            "bubbles": false,
            "cancelBubble": false,
            "cancelable": false,
            "lengthComputable": true,
            "loaded": 143044608,
            "total": 143044608,
            "target": null
          }
        */
      };

      const trustHosts = true;
      const options = {};
      console.log('downloading url ' + ' url ' + ' to ' + _this.path);
      ts = +new Date();
      _this.ft.download(url, _this.path + file + '.db4', successHandler, errorHandler, trustHosts, options);

    }, false);


  }

  processDownloadQueue() {
    if (this.state.queue.length > 0) {
      this.download(this.state.queue[0]);
    } else {
      console.log('*** done with download queue');
      this.state.downloading = false;
      console.log('sending downloadState.next');
      this.downloadState.next(this.state);
      this.downloadsComplete.next(true);
      // this.bookshelfChanged.next(true);
    }
  }

  toggleDownloadProcess() {
    console.log('*** toggleDownloadProcess()');
    if (this.state.downloading) { // stop downloading
      console.log('STOP DOWNLOADING');
      this.ft.abort();
      this.state.downloading = false;
      this.downloadState.next(this.state);
    } else { // start downloading
      console.log('START DOWNLOADING');
      this.processDownloadQueue();
      this.state.downloading = true;
      this.downloadState.next(this.state);
    }
  }

  public deleteVolume(vol: string) {
    console.log('** downloadService.deleteVolume', vol);
    return new Promise((resolve, reject) => {
      this.listDir(this.path)
        .then((fileSystem: any) => {
          console.log('got fileSystem');
          console.log('trying to remove file: ', vol + '.db4');
          console.log(this.path + vol + '.db4');
          fileSystem.getFile(vol + '.db4', { create: false }, (fileEntry) => {
            console.log('got fileEntry');
            fileEntry.remove((file) => {
              console.log('file removed');
              this.dbshuttleService.sqlBatch('bookshelf', [`update volume set installed = 'N' where tbl = '${vol}'`])
                .then((success) => {
                  this.bookshelfChanged.next(true);
                  resolve(true);
                }).catch((err) => {
                  console.error('Error updating bookself to set ' + vol + ' as not installed', err);
                  reject(err);
                });
            }, (error) => {
              console.error('error removing file', error);
              reject(error);
            }, () => {
              console.error('file ' + vol + ' does not exist');
            });
          }, (getFileError) => {
            console.error('getFileError', getFileError);
            if (getFileError.code === 1) { // file is already deleted
              this.dbshuttleService.sqlBatch('bookshelf', [`update volume set installed = 'N' where tbl = '${vol}'`])
                .then((success) => {
                  this.bookshelfChanged.next(true);
                  resolve(true);
                }).catch((err) => {
                  console.error('Error updating bookself to set ' + vol + ' as not installed', err);
                  reject(err);
                });
            }
          });
        }).catch((err) => {
          console.error('error in listDir', err);
          reject(err);
        });
    });
  }


  listDir(path) {
    return new Promise((resolve, reject) => {
      window.resolveLocalFileSystemURL(path, (fileSystem: any) => {
        // console.log('listDir got fileSystem', fileSystem);
        resolve(fileSystem);
      }, (err) => {
        console.error('listDir error', err);
        reject(err);
      });
    });
  }



}

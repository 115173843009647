import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SqliteDbCopy } from '@ionic-native/sqlite-db-copy/ngx';
import { DbshuttleService } from './dbshuttle.service';
import { Storage } from '@ionic/storage';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public isCordova = false;
  constructor(
    private platform: Platform,
    private sqliteDbCopy: SqliteDbCopy, /* private file: File */
    private dbshuttleService: DbshuttleService,
    private storage: Storage
  ) {
    this.isCordova = this.platform.is('cordova');
    console.log('this.isCordova', this.isCordova);
    // console.log('this.file', this.file);
  }

  public init() {
    // console.log('** inside initializeDatabases...()');
    // console.log('this.isCordova', this.isCordova);
    if (this.isCordova) {
      this.storage.get('initializeStorageDate')
      .then((initializeStorageDate) => {
        if (initializeStorageDate === null) {

          this.copyAllFiles();
          this.findDatabaseFilesToInstall();
          this.listIntalledFiles();

          this.initializeStorage()
          .then((initializeStorageResult) => {
            console.error('initializeStorageResult', initializeStorageResult);
            this.storage.set('initializeStorageDate', (+new Date()));
          }).catch((initializeStorageError) => {
            console.error('initializeStorageError', initializeStorageError);
          });
        } else {
          // console.log('skipping initializeStorage, already done: ', initializeStorageDate);
        }
      }).catch((err) => {
        console.error('Error checking storage for initializeStorageDate');
      });


    }
  }

  copyAllFiles() {
    const files = ['bookshelf.db4', 'kjv.db4'];
    files.map((file) => {
      this.sqliteDbCopy.copy(file, 0)
      .then((result) => {
        console.log('file copy result', result);
      }).catch((err) => {
        console.error('file copy error', err);
      });
    });
    /*
    this.listDir(this.file.applicationDirectory + 'www/')
    .then((files: any) => {
      for (const file of files) {
        if (file.name.endsWith('.db4')) {
          console.log('*** db4 file', file);
          this.sqliteDbCopy.copy(file.name, 0)
          .then((result) => {
            console.log('file copy result', result);
          }).catch((err) => {
            console.error('file copy error', err);
          });
        }
      }
    }).catch((err) => {
      console.error('*** files error', JSON.stringify(err));
    });
    */
}

findDatabaseFilesToInstall() {
  /*
  console.log('window.cordova.file.applicationDirectory', window.cordova.file.applicationDirectory);
  console.log('window.cordova.file.applicationStorageDirectory', window.cordova.file.applicationStorageDirectory);
  console.log('window.cordova.file.dataDirectory', window.cordova.file.dataDirectory);
  console.log('window.cordova.file.documentsDirectory', window.cordova.file.documentsDirectory);
  console.log('window.cordova.file.externalApplicationStorageDirectory', window.cordova.file.externalApplicationStorageDirectory);
  console.log('window.cordova.file.externalDataDirectory', window.cordova.file.externalDataDirectory);
  console.log('window.cordova.file.externalRootDirectory', window.cordova.file.externalRootDirectory);
  console.log('window.cordova.file.sharedDirectory', window.cordova.file.sharedDirectory);
  console.log('window.cordova.file.syncedDataDirectory', window.cordova.file.syncedDataDirectory);
  console.log('window.cordova.file.tempDirectory', window.cordova.file.tempDirectory);
  */
  const path = window.cordova.file.applicationDirectory + 'www/';
  console.log('path = ' + path);
  this.listDir(path)
  .then((fileSystem: any) => {
    const reader = fileSystem.createReader();
        reader.readEntries(
          function(entries) {
            entries.map((file: any) => {
              if (file.name.endsWith('.db4')) {
                console.log('www FILE FOUND: ' + file.name);
                // console.log('file.fullPath: ' + file.fullPath);
                // console.log('file.nativeURL: ' + file.nativeURL);
              }
            });
          },
          function(err) {
            console.error('reader.readEntries error:', err);
          });
  }).catch((err) => {
    console.error('test error', err);
  });
}

listIntalledFiles() {
  let path = window.cordova.file.applicationStorageDirectory;
  if (this.platform.is('ios')) {
    path += 'Library/LocalDatabase/';
  } else if (this.platform.is('android')) {
    path += 'databases/';
  } else {
    console.error('listIntalledFiles error, platform is not ios or android');
  }
  const _this = this;
  console.log('path = ' + path);
  this.listDir(path)
  .then((fileSystem: any) => {
    const installedFiles = [];
    const reader = fileSystem.createReader();
        reader.readEntries(
          function(entries) {
            entries.map((file: any) => {
              if (file.name.endsWith('.db4')) {
                installedFiles.push(file.name.replace('.db4', ''));
                console.log('INSTALLED FILE FOUND: ' + file.name);
                // console.log('file.fullPath: ' + file.fullPath);
                // console.log('file.nativeURL: ' + file.nativeURL);
              }
            });

            // console.log('running sql: ');
            // console.log(`update volume set installed = 'Y' where tbl in ('${installedFiles.join('\',\'')}')`);
            _this.dbshuttleService.getSQL('bookshelf', `update volume set installed = 'Y' where tbl in ('${installedFiles.join('\',\'')}')`)
            .then((result) => {
              // console.log('update installed result', result);
            }).catch((err) => {
              console.error('update installed error', err);
            });
  
          },
          function(err) {
            console.error('reader.readEntries error:', err);
          });
  }).catch((err) => {
    console.error('test error', err);
  });
}


listDir(path) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(path, (fileSystem: any) => {
      // console.log('listDir got fileSystem', fileSystem);
      resolve(fileSystem);
    }, (err) => {
      console.error('listDir error', err);
      reject(err);
    });
  });
}

initializeStorage() {
  return new Promise((resolve, reject) => {
    // console.log('***************************');
    // console.log('**** initializeStorage ****');
    // console.log('***************************');

    const statementArray = [];
    statementArray.push('create table if not exists keys (user,vol,key)');
    statementArray.push('create unique index if not exists ix_key on keys (user,vol)');
    statementArray.push('create table if not exists markers (id,user,vol,title,icon,iconcolor,book,chapter,verse,word,endBook,endChapter,endVerse,endWord,style,note,tags,ts,deleted,displayRef,folder,sortKey)');
    statementArray.push('create unique index if not exists ix_marker_id on markers (id)');
    statementArray.push('create index if not exists ix_marker on markers (user,vol,book,chapter,verse,word,endBook,endChapter,endVerse,endWord)');
    statementArray.push('create index if not exists ix_marker_ts on markers (user,ts)');
    statementArray.push('create table if not exists localsettings (user,setting,value,type,ts)');
    statementArray.push('create unique index if not exists ix_localsettings on localsettings (user,setting)');

    statementArray.push('create table if not exists history (user,vol,volumeClass,book,chapter,verse,title,abstract,ts)');
    statementArray.push('create unique index if not exists ix_history on history (user,vol,book,chapter,verse)');

    this.dbshuttleService.sqlBatch('storage', statementArray)
    .then((result) => {
      // console.log('initialize storage create tables result', result);
      this.dbshuttleService.updatePurchases()
      .then((updatePurchasesResult) => {
        console.log('loaded anonymous user keys', updatePurchasesResult);
      }).catch((err) => {
        console.error('Error updating anonymous user keys', err);
      });
      resolve(result);
    }).catch((err) => {
      console.error('initialize storage create tables error', err);
      reject(err);
    });

  });
}



/*
  listDir(path) {
    return new Promise((resolve, reject) => {
      this.file.resolveLocalFilesystemUrl(path)
      .then((fileSystem: any) => {
        const reader = fileSystem.createReader();
        reader.readEntries(
          function(entries) {
            resolve(entries);
          },
          function(err) {
            console.error('reader.readEntries error:', err);
            reject(err);
          }
        );
      }).catch((err) => {
        console.error('window.resolveLocalFileSystemURL error:',err);
        reject(err);
      });
    });
  }
*/

}

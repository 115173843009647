import { Injectable } from '@angular/core';
import { StringbuilderService } from './stringbuilder.service';
import { DbshuttleService } from './dbshuttle.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private sb: StringbuilderService, private dbshuttleService: DbshuttleService) { }

  searchVolumeSQL(vol: string, query: string, matchMode: string, offset: number): string {
    query = this.sanitizeSearchText(query);
    const queryArray = query.split(' ');
    let iNum;
    let searchBlock = '';
    let matchString = queryArray[0];
    // console.log('** searchVolume, vol is: ' + vol);
    // console.log('*** matchMode: ' + matchMode);
    switch (matchMode) {
      case 'All Words':
        console.log('*** all words, vol: ' + vol);
        if (vol === 'MARKERS') {
          matchString = '';
          for (iNum = 0; iNum < queryArray.length; iNum++) {
            if (iNum > 0) {
              matchString += ' AND ';
            }
              matchString += ' FIELD_NAME like \'%' + queryArray[iNum] + '%\' ';
          }
          // console.log('*** matchString: ' + matchString);
          searchBlock = '(' + matchString + ')'; // " OR (" + matchString.replace(/FIELD_NAME/g,"title") + ")";
          // console.log('*** searchBlock: ' + searchBlock);
        } else {
          for (iNum = 1; iNum < queryArray.length; iNum++) {
              matchString += ` ${queryArray[iNum]}`;
          }
          searchBlock = `FT.txt MATCH '${matchString}' `; // stringWithFormat('FT.txt MATCH \'%@\' ', matchString);
        }
        break;
      case 'Any Words':
        if (vol === 'MARKERS') {
          matchString = '';
          for (iNum = 0; iNum < queryArray.length; iNum++) {
            if (iNum > 0) {
              matchString += ' OR ';
            }
              matchString += ` FIELD_NAME like '%${queryArray[iNum]}%' `; // ' FIELD_NAME like \'%' + + '%\' ';
          }
          searchBlock = `(${matchString})`; // '(' + matchString + ')'; // " OR (" + matchString.replace(/FIELD_NAME/g,"title") + ")";
          // searchBlock = "(" + matchString.replace(/FIELD_NAME/g,"note") + ") OR (" + matchString.replace(/FIELD_NAME/g,"title") + ")";
        } else {
          for (iNum = 1; iNum < queryArray.length; iNum++) {
              matchString += ` OR ${queryArray[iNum]}`; // stringWithFormat(' OR %@', queryArray[iNum]);
          }
          searchBlock = `FT.txt MATCH '${matchString}' `; // stringWithFormat('FT.txt MATCH \'%@\' ', matchString);
        }
        break;
      case 'Entire Phrase':
        if (vol === 'MARKERS') {
          matchString = '';
          matchString += ` FIELD_NAME like '%${queryArray.join(' ')}%' `; // ' FIELD_NAME like \'%' + queryArray.join(' ') + '%\' ';
          searchBlock = `(${matchString})`; // '(' + matchString + ')';// " OR (" + matchString.replace(/FIELD_NAME/g,"title") + ")";
          // searchBlock = "(" + matchString.replace(/FIELD_NAME/g,"note") + ") OR (" + matchString.replace(/FIELD_NAME/g,"title") + ")";
        } else {
          for (iNum = 1; iNum < queryArray.length; iNum++) {
              matchString += ` OR ${queryArray[iNum]}`; // stringWithFormat(' OR %@', queryArray[iNum]);
          }
          searchBlock = `FT.txt MATCH '${matchString}' `; // stringWithFormat('FT.txt MATCH \'%@\' ', matchString);
          searchBlock = `FT.txt MATCH '"${query}"' `;  // stringWithFormat('FT.txt MATCH \'"%@"\' ', vm.query);
        }
        break;
      default:
        // popupsService.showAlert('Error','Unknown match mode: ' + vm.matchMode);
        break;
    }
    // stringWithFormat('select ' + '\'' + vol + '\' as vol,' + ' 
    // snippet(FT,\'<b><u>\',\'</u></b>\') as snippet, V.*,BOOK.* from CONTENT as V 
    // INNER JOIN FT on FT.rowid = V.rowid LEFT OUTER JOIN BOOK on BOOK.book = V.BOOK 
    // LEFT OUTER JOIN SECTION on SECTION.section = BOOK.section 
    // where %@ order by V.book,V.chapter,V.verse limit %@', searchBlock, vm.limit);
    let sql;
    if (offset === -1) { // count query
      sql = `select count(*) as total from CONTENT as V INNER JOIN FT on FT.rowid = V.rowid
      LEFT OUTER JOIN BOOK on BOOK.book = V.BOOK
      LEFT OUTER JOIN SECTION on SECTION.section = BOOK.section
      where ${searchBlock}`;
    } else {
      sql = `select '${vol}' as vol, snippet(FT,'<b><u>','</u></b>') as snippet,
      V.*,BOOK.* from CONTENT as V INNER JOIN FT on FT.rowid = V.rowid
      LEFT OUTER JOIN BOOK on BOOK.book = V.BOOK
      LEFT OUTER JOIN SECTION on SECTION.section = BOOK.section
      where ${searchBlock}
      order by V.book,V.chapter,V.verse limit 100 offset ${offset}`;
    }
// console.log('** sql: ' + sql);

    if (vol === 'MARKERS') {
      /*
      let userID = '';
      if (userService.currentUser !== null && userService.currentUser.id) {
        userID = userService.currentUser.id;
      }
      // console.log('******** searchBlock: ' + searchBlock);
      // select title,'notes' as name,note as snippet from markers where ( FIELD_NAME like '%allow%' ) and deleted = '' order by book,chapter,verse,word
      sql = 'select id,vol,book,chapter,verse,word,title,\'Marker Notes\' as name,displayRef || \' \' || case vol when \'ANY\' then \'\' else \' \' || UPPER(vol) end || \': \' || substr(note,1,50)||\'...\' as snippet from markers where ' +
        searchBlock.replace(/FIELD_NAME/g,'note') +
        ' and deleted = \'\' and user = \'' + userID + '\' and book > -1' +
        ' union ' +
        'select id,vol,book,chapter,verse,word,title,\'Bible Studies\' as name,title as snippet from markers where (' +
          searchBlock.replace(/FIELD_NAME/g,'note') + ' OR ' + searchBlock.replace(/FIELD_NAME/g,'title') + ') ' +
          ' and deleted = \'\' and user = \'' + userID + '\' and book = -2' +
          ' union ' +
        'select id,vol,book,chapter,verse,word,title,\'Marker Titles\' as name,displayRef || case vol when \'ANY\' then \'\' else \' \' || UPPER(vol) end || \' \' || title as snippet from markers where ' +
          searchBlock.replace(/FIELD_NAME/g,'title') +
          ' and deleted = \'\' and user = \'' + userID + '\' and book > -1' +         ' order by book,chapter,verse,word';
      // console.log('**** SQL: ' + sql);
      vol = 'storage';
      */
    }
    /*
    $ionicLoading.show({
      template: 'Searching ' + vol.toUpperCase() + '...'
    });
    */
        /*
   this.dbshuttleService.getSQL(vol, sql)
      .then((rows) => {
        console.log('*** search gave rows', rows);
        vm.grandTotal += rows.length;
        // console.log('*** vol is: ' + vol);
        if (vol === 'storage') {
          for (let rr=0; rr < rows.length; rr++) {
            // console.log('*** replacing marker notes here');
            rows[rr].snippet = rows[rr].snippet.replace(/<br\s?\/?>/g,' ').replace(/<\/?p>/g,' ');
          }
        }
        vm.resultSets.push({
          vol: (vol==='storage'?'markers':vol),
          query: vm.query,
          matchMode: vm.matchMode,
          limit: vm.limit,
          groups: _.chain(rows).groupBy('name').pairs().value(),
          total: rows.length,
          displayed: vm.volumeList.length===1,
          grandTotal: 0
        });
        // vm.groups = _.chain(rows).groupBy('name').pairs().value();

        $ionicLoading.hide();
        searchVolume();
      }).catch(function(err) {
        console.log('ERROR: ' + JSON.stringify(err, null, 2));
        $ionicLoading.hide();
      });
  }).catch((err) => {
    console.error('getSQL error', err);
  });
      */
     return sql;
  }

  public sanitizeSearchText(text) {
    const cleanText = this.sb.builder(text);

    // replace punctuation instead of filtering by byte value so decomposed UTF8 code points will search correctly.

    cleanText.replace(/\?/g, '*');
    cleanText.replace(/,/g, ' ');
    cleanText.replace(/!/g, ' ');
    cleanText.replace(/@/g, '');
    cleanText.replace(/#/g, '');
    cleanText.replace(/\$/g, '');
    cleanText.replace(/%/g, '');
    cleanText.replace(/&/g, '');
    cleanText.replace(/\(/g, '');
    cleanText.replace(/\\/g, '');
    cleanText.replace(/\+/g, '');
    cleanText.replace(/\./g, '');
    cleanText.replace(/\//g, '');

    // Ti.API.info("place 1");
    cleanText.replace(/:/g, '');
    cleanText.replace(/;/g, '');
    cleanText.replace(/</g, '');
    cleanText.replace(/>/g, '');
    cleanText.replace(/=/g, '');
    cleanText.replace(/\[/g, '');
    cleanText.replace(/\]/g, '');
    // Ti.API.info("place 2");
    cleanText.replace(/\^/g, '');
    cleanText.replace(/`/g, '');
    cleanText.replace(/{/g, '');
    cleanText.replace(/}/g, '');
    cleanText.replace(/~/g, '');
    cleanText.replace(/"/g, '');
    cleanText.replace(/'/g, '');
    cleanText.replace(/’/g, '');
    cleanText.replace(/‘/g, '');
    cleanText.replace(/”/g, '');
    cleanText.replace(/“/g, '');


    // remove any double spaces

    while(cleanText.toString().indexOf('  ') > 0) {
      cleanText.replace(/  /g, ' ');
    }


    // trim leading and trailing spaces now
    const returnString = cleanText.toString().trim();
    // Ti.API.info("cleaned search string - "+returnString);
    return returnString;

    // return cleanText;
  }

}

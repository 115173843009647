import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public alertController: AlertController,
    public toastController: ToastController
  ) { }

  public async alert(title, message) {
    const alert = await this.alertController.create({
      header: title,
      // tslint:disable-next-line: object-literal-shorthand
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: (blah) => {
            console.log('OK pressed');
          }
        }
      ]
    });
    await alert.present();
  }

  public async confirm(title, message, confirmButtonTitle, confirmActionHandler) {
    const alert = await this.alertController.create({
      header: title,
      // tslint:disable-next-line: object-literal-shorthand
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('confirm canceled');
          }
        }, {
          text: confirmButtonTitle,
          handler: confirmActionHandler        }
      ]
    });
    await alert.present();
  }

  public async presentToast(message, duration = 2000) {
    const toast = await this.toastController.create({
      // tslint:disable-next-line: object-literal-shorthand
      message: message,
      // tslint:disable-next-line: object-literal-shorthand
      duration: duration
    });
    toast.present();
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MarkersService } from '../../services/markers.service';

@Component({
  selector: 'app-action-bar-quick-bookmark',
  templateUrl: './action-bar-quick-bookmark.page.html',
  styleUrls: ['./action-bar-quick-bookmark.page.scss'],
})
export class ActionBarQuickBookmarkPage implements OnInit {
  public dark = false;
  // public v: number;
  public iconChoices;
  public iconColorChoices;
  public styleChoices;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public m: MarkersService
  ) { }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.dark) {
      this.dark = true;
    }
      this.iconChoices = [
        'bookmark',
        'musical-notes',
        'star',
        'bulb',
        'pin',
        'heart',
        'flag',
        'key',
        'image',
        // 'arrow-forward',
        // 'arrow-back',
        // 'arrow-up',
        // 'arrow-down'
      ];
      this.iconColorChoices = [
        'black',
        'blue',
        'cyan',
        'gold',
        'gray',
        'green',
        'orange',
        'purple',
        'red',
        'tan',
      ];
      this.styleChoices = [
        'yellow',
        'green',
        'pink',
        'blue',
        'orange',
        'cyan',
        'tan',
        'purple',
        'gray',
        'bold',
        'underline',
        'double',
        'dotted',
        'dashed',
        'overline',
        'reverse'
      ];
  }
  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { settings: this.m.defaultMarkerSettings }
    );
  }
  selectIcon(icon) {
    this.m.defaultMarkerSettings.icon = icon;
    this.m.saveDefaultMarkerSettings();
  }
  selectColor(color) {
    this.m.defaultMarkerSettings.iconcolor = color;
    this.m.saveDefaultMarkerSettings();
  }

  placeBookmark() {
    this.closeModal(false); // do not cancel
  }

}

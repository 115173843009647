import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DisplaySettingsPageRoutingModule } from './display-settings-routing.module';

import { DisplaySettingsPage } from './display-settings.page';
import { ColorTwitterModule } from 'ngx-color/twitter'; // <color-twitter></color-twitter>
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DisplaySettingsPageRoutingModule,
    ColorTwitterModule
  ],
  declarations: [DisplaySettingsPage],
  bootstrap: [DisplaySettingsPage]
})
export class DisplaySettingsPageModule {}

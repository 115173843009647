import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import { DbLocalSettingsService } from '../../services/db-local-settings.service';
// import { ChangeDetectorRef } from '@angular/core';
import { MarkersService } from '../../services/markers.service';

@Component({
  selector: 'app-action-bar-settings',
  templateUrl: './action-bar-settings.page.html',
  styleUrls: ['./action-bar-settings.page.scss'],
})
export class ActionBarSettingsPage implements OnInit {
  public dark = false;
  // public v: number;
  public iconChoices;
  public iconColorChoices;
  public styleChoices;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    // private dbLocalSettingsService: DbLocalSettingsService,
    public m: MarkersService
    // private changeDetectorRef: ChangeDetectorRef
  ) { }

  ionViewWillEnter() {
    console.log('*** ionViewWillEnter');
    console.log('this.m.defaultMarkerSettings', this.m.defaultMarkerSettings);
  }
  ionViewDidEnter() {
    console.log('this.m.defaultMarkerSettings', this.m.defaultMarkerSettings);
  }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.dark) {
      this.dark = true;
    }
      this.iconChoices = [
        'bookmark',
        'musical-notes',
        'star',
        'bulb',
        'pin',
        'heart',
        'flag',
        'key',
        'image',
        // 'arrow-forward',
        // 'arrow-back',
        // 'arrow-up',
        // 'arrow-down'
      ];
      this.iconColorChoices = [
        'black',
        'blue',
        'cyan',
        'gold',
        'gray',
        'green',
        'orange',
        'purple',
        'red',
        'tan',
      ];
      this.styleChoices = [
        'yellow',
        'green',
        'pink',
        'blue',
        'orange',
        'cyan',
        'tan',
        'purple',
        'gray',
        'bold',
        'underline',
        'double',
        'dotted',
        'dashed',
        'overline',
        'reverse'
      ];
  }
  async closeModal(cancel?: boolean) {
    // const onClosedData: string = "Wrapped Up!";
    // await this.modalController.dismiss(onClosedData);
    await this.modalController.dismiss(
      cancel ? null : { key: 'value' }
    );
  }
  selectIcon(icon) {
    this.m.defaultMarkerSettings.icon = icon;
    this.m.saveDefaultMarkerSettings();
  }
  selectColor(color) {
    this.m.defaultMarkerSettings.iconcolor = color;
    this.m.saveDefaultMarkerSettings();
  }
  selectStyle(style) {
    this.m.defaultMarkerSettings.style = style;
    this.m.saveDefaultMarkerSettings();
  }


}

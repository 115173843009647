import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { DbLocalSettingsService } from '../services/db-local-settings.service';

@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {
  constructor(private dbLocalSettingsService: DbLocalSettingsService, private router: Router) {}

  canLoad() {
    return this.dbLocalSettingsService.getLocalSetting('ion_did_tutorial')
    .then((res: any) => {
      if (res) {
        return this.dbLocalSettingsService.getLocalSetting('lastPage')
        .then((lastPage: any) => {
          if (lastPage && lastPage.path) {
            this.router.navigateByUrl(lastPage.path);
            return false;
          } else {
            this.router.navigateByUrl('/app/tabs/read');
            return false;
          }  
        }).catch((err) => {
          console.error('check-tutorial error loading lastPage', err);
          this.router.navigateByUrl('/app/tabs/read');
          return false;
        });
      } else {
        return true;
      }
    });
  }
}

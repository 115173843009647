import { Component } from '@angular/core';

import { PopoverController } from '@ionic/angular';

@Component({
  template: `
    <ion-list>
      <ion-item-divider>
        Display Format
      </ion-item-divider>
      <ion-item lines="full" (click)="close('FormatSmallButtons')">
        <ion-icon name="grid-outline" slot="start"></ion-icon>
        <ion-label>Grid (short)</ion-label>
      </ion-item>
      <ion-item lines="full" (click)="close('FormatLargeButtons')">
        <ion-icon name="grid-outline" slot="start"></ion-icon>
        <ion-label>Grid (full)</ion-label>
      </ion-item>
      <ion-item lines="full" (click)="close('FormatScrollingList')">
      <ion-icon name="list-outline" slot="start"></ion-icon>
        <ion-label>List</ion-label>
      </ion-item>
    </ion-list>
  `
})
export class PopoverPage {
  constructor(public popoverCtrl: PopoverController) {}

  close(message: string) {
    this.popoverCtrl.dismiss(message);
  }

}
